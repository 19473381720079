import { useQuery } from '@tanstack/react-query';
import getFronteggTokenData from '../api/frontegg/getFronteggTokenData';

export function useToken() {
    const { data, ...rest } = useQuery(['getTokenData'], getFronteggTokenData, {
        refetchOnMount: false,
        refetchOnWindowFocus: true,
    });

    return {
        data,
        ...rest,
    };
}
