import { IDocument } from '../../../@types/Document';
import DocumentTagChip from '../DocumentTagChip';

interface IDocumentTagsProps {
    documentData: IDocument;
    selectedTags: string[];
    onClickTag: (tag: string) => void;
    onClickMore: () => void;
}

const DocumentTags = ({
    documentData,
    selectedTags,
    onClickTag,
    onClickMore,
}: IDocumentTagsProps) => {
    const getMoreCount = () => {
        return selectedTags.length - 2;
    };

    return (
        <span
            style={{
                height: '38px',
            }}
        >
            {selectedTags
                .filter((_v, index) => index < 2)
                .map((tag, index) => {
                    return (
                        <DocumentTagChip
                            key={index}
                            label={tag}
                            title={tag.length > 10 ? tag : null}
                            onClick={() => {
                                onClickTag(tag);
                            }}
                            color={documentData.tags[tag]}
                        />
                    );
                })}
            {selectedTags.length > 2 && (
                <DocumentTagChip
                    label={`+${getMoreCount()} more tag${getMoreCount() > 1 ? 's' : ''}`}
                    title="View all tags"
                    onClick={() => {
                        onClickMore();
                    }}
                    color="gainsboro"
                />
            )}
        </span>
    );
};

export default DocumentTags;
