import { Badge, Box, Button, IconButton, InputAdornment, Modal, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Flex from 'styled-flex-component';
import { H5, Text } from '../../UI/Typography';
import {
    AddCircleOutline,
    Close,
    CloseOutlined,
    DragIndicator,
    RemoveCircleOutline,
    SearchOutlined,
    Visibility,
    VisibilityOff,
} from '@mui/icons-material';
import Container from '../../UI/Common/Container';
import { useEffect, useState } from 'react';
import { Theme } from '../../../theme';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { useDocumentTypes } from '../../../hooks/useDocumentTypes';
import { StyledSearchTextField } from '../../AdvancedSearch/SearchSectionInput/StyledTextField';
import { isEmpty } from 'lodash';
import { createTemplate } from '../../../api/template.api';
import { toast } from 'react-toastify';
import { ITemplate } from '../../../@types/Template';
import HiddenInsightsBadge from '../../UI/Badge/HiddenInsightsBadge';
import { MeliorTranslate } from '../../MeliorTranslate';

interface ICreateListModalProps {
    isOpen: boolean;
    setIsOpen: (val: boolean) => void;
    clauses?: string[];
    onSaveTemplateSuccess: (template: ITemplate) => void;
    maxStep: number;
    visibleClauses?: string[];
    docType?: string;
    mode: string;
    template?: ITemplate;
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    p: 4,
    borderRadius: '10px',
    height: 400,
};

export default function CreateListModal({
    isOpen,
    setIsOpen,
    clauses,
    onSaveTemplateSuccess,
    maxStep,
    visibleClauses,
    docType,
    mode,
    template,
}: ICreateListModalProps) {
    const { t } = useTranslation();
    const [name, setName] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [visibles, setVisibles] = useState<string[]>([]);
    const [hiddens, setHiddens] = useState<string[]>([]);
    const [step, setStep] = useState<number>(1);
    const { docTypes, isLoading } = useDocumentTypes();
    const [selectedDocTypes, setSelectedDocTypes] = useState<string[]>([]);
    const [filteredDocTypes, setfilteredDocTypes] = useState<string[]>([]);
    const [searchText, setSearchText] = useState<string>();
    const [currentInsight, setCurrentInsight] = useState<string>();
    const [isSaving, setIsSaving] = useState<boolean>();
    const subtitles = [
        `${
            mode == 'edit'
                ? 'Edit template name and description'
                : 'Add template name and description'
        }`,
        'Drag and drop insights to change position',
        'Select document types to apply insight lists',
        'Review template values',
    ];

    useEffect(() => {
        setCurrTemplate(mode == 'edit' ? template : ({} as ITemplate));
    }, [template, mode]);

    const setCurrTemplate = (template) => {
        setName(template.template_name ?? '');
        setDescription(template.template_description ?? '');
        setSelectedDocTypes(template.linked_doc_types ?? []);
        setVisibles(template.visible ?? clauses ?? []);
        setHiddens(
            isEmpty(template.visible)
                ? []
                : clauses?.filter((clause) => !template.visible.includes(clause)) ?? []
        );
    };

    useEffect(() => {
        setVisibles(clauses ?? []);
    }, []);

    useEffect(() => {
        if (!isLoading) {
            setfilteredDocTypes(docTypes);
            setSelectedDocTypes([]);
        }
    }, [isLoading]);

    const resetModal = () => {
        setCurrTemplate(mode == 'edit' ? template : ({} as ITemplate));
        setfilteredDocTypes([]);
        setSearchText('');
        setStep(1);
    };

    const scrollToDisplayBottom = (elementId) => {
        const element: any = document.getElementById(elementId);
        element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    };

    const dragEnd = (result) => {
        const { destination, source, draggableId } = result;
        // Released outside of droppable area
        if (!destination) return;

        // Order not changed
        if (source.index == destination.index && source.droppableId == destination.droppableId)
            return;

        if (source.droppableId != destination.droppableId) {
            //remove from source
            const sourceList = source.droppableId == 'displayed' ? visibles : hiddens;
            const clause = sourceList.find((clause) => clause == draggableId);
            sourceList.splice(source.index, 1);
            //insert to destination
            const destinationList = destination.droppableId == 'displayed' ? visibles : hiddens;
            destinationList.splice(destination.index, 0, clause as string);
            // update lists
            const displayed = source.droppableId == 'displayed' ? sourceList : destinationList;
            const hidden = source.droppableId == 'hidden' ? sourceList : destinationList;
            console.log(visibles, hidden);
            setVisibles(displayed.concat());
            setHiddens(hidden.concat());
            return;
        }

        const listToUpdate = source.droppableId == 'displayed' ? visibles : hiddens;
        const clause = listToUpdate.find((clause) => clause == draggableId);
        listToUpdate.splice(source.index, 1);
        listToUpdate.splice(destination.index, 0, clause as string);
        source.droppableId == 'displayed' ? setVisibles(listToUpdate) : setHiddens(listToUpdate);
    };

    const isValidStep = () => {
        if (step == 1) return !isEmpty(name) && !isEmpty(description);
        if (step == 2) return !isEmpty(visibles);
        if (step == 3) return !isEmpty(selectedDocTypes);

        return true; //return true for step 4 (review)
    };

    const save = async () => {
        try {
            setIsSaving(true);
            const template = {
                template_name: name ?? '',
                template_description: description ?? '',
                linked_doc_types: step == 4 ? selectedDocTypes : [docType ?? ''],
                visible: step == 4 ? visibles : visibleClauses ?? [],
            };
            await createTemplate(template).then((res) => {
                if (res) {
                    toast.success(
                        t(
                            mode == 'edit'
                                ? 'Template updated successfully'
                                : 'Template saved successfully.'
                        )
                    );
                    onSaveTemplateSuccess(template);
                    resetModal();
                }
                setIsSaving(false);
            });
        } catch (e) {
            toast.error(t('Error saving template.'));
        }
    };

    const close = () => {
        resetModal();
        setIsOpen(false);
    };

    return (
        <Modal open={isOpen} aria-labelledby="add-tag-title" aria-describedby="add-tag-description">
            <Box sx={style}>
                {/* Title */}
                <Flex
                    justifyBetween
                    alignCenter
                    style={{ marginBottom: step == 1 ? '20px' : '10px' }}
                >
                    <Flex column>
                        <H5>
                            <MeliorTranslate
                                valueKey={mode === 'edit' ? 'Edit list' : 'Create List'}
                            />
                        </H5>
                        <Text style={{ height: '20px', marginTop: '10px' }}>
                            {t(subtitles[step - 1])}
                        </Text>
                    </Flex>
                    <Container onClick={() => close()}>
                        <CloseOutlined sx={{ color: 'rgba(0, 0, 0, 0.54)', cursor: 'pointer' }} />
                    </Container>
                </Flex>

                {/* Step 1 */}
                {Boolean(step == 1) && (
                    <div>
                        {/* Form */}
                        <Flex column style={{ gap: '10px', marginTop: '10px' }}>
                            {/* Name */}
                            <TextField
                                required
                                id="outlined-required"
                                label={t('Name')}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                style={{ width: '100%' }}
                            />
                            {/* Description */}
                            <TextField
                                required
                                id="outlined-required"
                                label={t('Description')}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                style={{ width: '100%' }}
                                multiline
                                rows={8}
                            />
                        </Flex>
                    </div>
                )}

                {/* Step 2 */}
                {Boolean(step == 2) && (
                    <div>
                        {/* Insights */}
                        <DragDropContext onDragEnd={dragEnd}>
                            <Flex style={{ gap: '10px', marginTop: '10px' }}>
                                <Flex column style={{ gap: '10px', width: 300 }}>
                                    {/* Label & Count */}
                                    <Flex alignCenter>
                                        <Text customFontWeight={500}>
                                            <MeliorTranslate valueKey="Displayed Insights" />
                                        </Text>
                                        <Badge
                                            badgeContent={visibles.length.toString()}
                                            sx={{ margin: 2 }}
                                            color="primary"
                                        />
                                    </Flex>
                                    <Droppable droppableId="displayed">
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                id="displayed-clauses"
                                                style={{
                                                    height: '240px',
                                                    overflowY: 'auto',
                                                    gap: '10px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                {visibles.map((clause, index) => (
                                                    <Draggable
                                                        draggableId={clause}
                                                        index={index}
                                                        key={clause + index}
                                                    >
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                key={index}
                                                                style={{
                                                                    ...provided.draggableProps
                                                                        .style,
                                                                    left: 'auto !important',
                                                                    top: 'auto !important',
                                                                }}
                                                                onMouseEnter={() =>
                                                                    setCurrentInsight(clause)
                                                                }
                                                                onMouseLeave={() =>
                                                                    setCurrentInsight('')
                                                                }
                                                            >
                                                                <Flex
                                                                    alignCenter
                                                                    justifyBetween
                                                                    style={{
                                                                        minHeight: '50px',
                                                                        border: '1px solid lightgray',
                                                                        borderRadius: '5px',
                                                                        paddingLeft: '10px',
                                                                        paddingRight: '10px',
                                                                        background: 'white',
                                                                    }}
                                                                >
                                                                    <Flex alignCenter>
                                                                        <IconButton
                                                                            color="default"
                                                                            style={{
                                                                                visibility:
                                                                                    currentInsight ==
                                                                                    clause
                                                                                        ? 'visible'
                                                                                        : 'hidden',
                                                                                height: '40px',
                                                                                marginRight: '5px',
                                                                            }}
                                                                        >
                                                                            <DragIndicator />
                                                                        </IconButton>
                                                                        <Text
                                                                            color={
                                                                                Theme.primaryDark
                                                                            }
                                                                        >
                                                                            {clause}
                                                                        </Text>
                                                                    </Flex>
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            setVisibles(
                                                                                visibles.filter(
                                                                                    (visible) =>
                                                                                        visible !=
                                                                                        clause
                                                                                )
                                                                            );
                                                                            setHiddens(
                                                                                hiddens.concat(
                                                                                    clause
                                                                                )
                                                                            );
                                                                            scrollToDisplayBottom(
                                                                                'hiddens-bottom'
                                                                            );
                                                                        }}
                                                                    >
                                                                        <VisibilityOff />
                                                                    </IconButton>
                                                                </Flex>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                                <div id="visibles-bottom" />
                                            </div>
                                        )}
                                    </Droppable>
                                </Flex>

                                <Flex column style={{ gap: '10px', width: 300 }}>
                                    {/* Label & Count */}
                                    <Flex alignCenter style={{ margin: 8 }}>
                                        <Text customFontWeight={500} style={{ marginRight: 16 }}>
                                            <MeliorTranslate valueKey="Hidden Insights" />
                                        </Text>
                                        <HiddenInsightsBadge content={hiddens.length} />
                                    </Flex>
                                    <Droppable droppableId="hidden">
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                id="displayed-clauses"
                                                style={{
                                                    height: '240px',
                                                    overflowY: 'auto',
                                                    gap: '10px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                {hiddens.map((clause, index) => (
                                                    <Draggable
                                                        draggableId={clause}
                                                        index={index}
                                                        key={clause + index}
                                                    >
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                key={index}
                                                                style={{
                                                                    ...provided.draggableProps
                                                                        .style,
                                                                    left: 'auto !important',
                                                                    top: 'auto !important',
                                                                }}
                                                                onMouseEnter={() =>
                                                                    setCurrentInsight(clause)
                                                                }
                                                                onMouseLeave={() =>
                                                                    setCurrentInsight('')
                                                                }
                                                            >
                                                                <Flex
                                                                    alignCenter
                                                                    justifyBetween
                                                                    style={{
                                                                        minHeight: '50px',
                                                                        border: '1px solid lightgray',
                                                                        borderRadius: '5px',
                                                                        paddingLeft: '10px',
                                                                        paddingRight: '10px',
                                                                        background: 'whitesmoke',
                                                                    }}
                                                                >
                                                                    <Flex alignCenter>
                                                                        <IconButton
                                                                            color="default"
                                                                            style={{
                                                                                visibility:
                                                                                    currentInsight ==
                                                                                    clause
                                                                                        ? 'visible'
                                                                                        : 'hidden',
                                                                                height: '40px',
                                                                                marginRight: '5px',
                                                                            }}
                                                                        >
                                                                            <DragIndicator
                                                                                style={{
                                                                                    color: 'darkgray',
                                                                                }}
                                                                            />
                                                                        </IconButton>
                                                                        <Text color="darkgray">
                                                                            {clause}
                                                                        </Text>
                                                                    </Flex>
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            const updatedList =
                                                                                hiddens.filter(
                                                                                    (hidden) =>
                                                                                        hidden !=
                                                                                        clause
                                                                                );
                                                                            setHiddens(updatedList);
                                                                            setVisibles(
                                                                                visibles.concat(
                                                                                    clause
                                                                                )
                                                                            );
                                                                            if (updatedList.length)
                                                                                scrollToDisplayBottom(
                                                                                    'visibles-bottom'
                                                                                );
                                                                        }}
                                                                    >
                                                                        <Visibility
                                                                            style={{
                                                                                color: 'darkgray',
                                                                            }}
                                                                        />
                                                                    </IconButton>
                                                                </Flex>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                                <div id="hiddens-bottom" />
                                            </div>
                                        )}
                                    </Droppable>
                                </Flex>
                            </Flex>
                        </DragDropContext>
                    </div>
                )}

                {/* Step 3 */}
                {Boolean(step == 3) && (
                    <Container>
                        <Flex alignCenter style={{ marginBottom: '10px' }}>
                            <Text customFontWeight={500}>
                                <MeliorTranslate valueKey="Document Types" />
                            </Text>
                            <Badge
                                badgeContent={selectedDocTypes.length}
                                sx={{ margin: 2 }}
                                color="primary"
                            />
                        </Flex>
                        <Container bottomOuterSpacing={1}>
                            <StyledSearchTextField
                                autoFocus
                                autoComplete="off"
                                fullWidth
                                placeholder={t('Search for document type')}
                                size="small"
                                value={searchText}
                                sx={{ background: 'rgba(0, 0, 0, 0.06)' }}
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                    setfilteredDocTypes(
                                        docTypes.filter((type) =>
                                            type
                                                .toLowerCase()
                                                .includes(e.target.value.toLowerCase())
                                        )
                                    );
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchOutlined />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {searchText && (
                                                <Container
                                                    topOuterSpacing={0.4}
                                                    cursor="pointer"
                                                    onClick={() => {
                                                        setSearchText('');
                                                        setfilteredDocTypes(docTypes);
                                                    }}
                                                >
                                                    <Close fontSize="small" />
                                                </Container>
                                            )}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Container>
                        <Container height="185px" style={{ overflowY: 'auto' }}>
                            {docTypes &&
                                (filteredDocTypes.length ? filteredDocTypes : docTypes).map(
                                    (type, index) => (
                                        <Container
                                            key={index}
                                            border="1px solid rgba(13, 24, 50, 0.12)"
                                            borderBottom={
                                                filteredDocTypes.length - 1 === index
                                                    ? '1px solid rgba(13, 24, 50, 0.12)'
                                                    : 'none'
                                            }
                                            innerSpacing={0.75}
                                            cursor="pointer"
                                            onClick={() => {
                                                if (selectedDocTypes) {
                                                    if (selectedDocTypes.includes(type)) {
                                                        setSelectedDocTypes(
                                                            selectedDocTypes.filter(
                                                                (selectedType) =>
                                                                    selectedType != type
                                                            )
                                                        );
                                                        return;
                                                    }
                                                }
                                                setSelectedDocTypes(selectedDocTypes.concat(type));
                                            }}
                                            backgroundColor={
                                                selectedDocTypes.includes(type)
                                                    ? 'rgba(16, 185, 129, 0.3)'
                                                    : ''
                                            }
                                        >
                                            <Flex alignCenter justifyBetween>
                                                <Text color="rgba(13, 24, 50, 0.87)">{type}</Text>
                                                {selectedDocTypes.includes(type) && (
                                                    <RemoveCircleOutline
                                                        sx={{ color: 'rgba(13, 24, 50, 0.54)' }}
                                                    />
                                                )}
                                                {!selectedDocTypes.includes(type) && (
                                                    <AddCircleOutline
                                                        sx={{ color: 'rgba(13, 24, 50, 0.54)' }}
                                                    />
                                                )}
                                            </Flex>
                                        </Container>
                                    )
                                )}
                        </Container>
                    </Container>
                )}

                {/* Step 4 */}
                {Boolean(step == 4) && (
                    <Flex column>
                        <Text customFontWeight={600}>{name}</Text>
                        <Text style={{ height: '35px', overflowY: 'auto', marginTop: '10px' }}>
                            {description}
                        </Text>
                        <Flex style={{ marginTop: '10px', gap: '20px' }}>
                            <div style={{ width: '50%' }}>
                                {/* Label & Count */}
                                <Flex alignCenter>
                                    <Text customFontWeight={600}>
                                        <MeliorTranslate valueKey="Displayed Insights" />
                                    </Text>
                                    <Badge
                                        badgeContent={visibles.length}
                                        sx={{ margin: 2 }}
                                        color="primary"
                                    />
                                </Flex>
                                <Flex
                                    column
                                    style={{
                                        height: '170px',
                                        overflowY: 'auto',
                                        gap: '5px',
                                        marginTop: '5px',
                                    }}
                                >
                                    {visibles.map((clause, index) => (
                                        <Flex
                                            key={index}
                                            alignCenter
                                            justifyBetween
                                            style={{
                                                minHeight: '50px',
                                                border: '1px solid lightgray',
                                                borderRadius: '5px',
                                                paddingLeft: '10px',
                                                paddingRight: '10px',
                                                background: 'white',
                                            }}
                                        >
                                            <Text color={Theme.primaryDark}>{clause}</Text>
                                        </Flex>
                                    ))}
                                </Flex>
                            </div>
                            <div style={{ width: '50%' }}>
                                {/* Label & Count */}
                                <Flex alignCenter>
                                    <Text customFontWeight={600}>
                                        <MeliorTranslate valueKey="Document Types" />
                                    </Text>
                                    <Badge
                                        badgeContent={selectedDocTypes.length}
                                        sx={{ margin: 2 }}
                                        color="primary"
                                    />
                                </Flex>
                                <Flex
                                    column
                                    style={{
                                        height: '170px',
                                        overflowY: 'auto',
                                        gap: '5px',
                                        marginTop: '5px',
                                    }}
                                >
                                    {selectedDocTypes.map((type, index) => (
                                        <Flex
                                            key={index}
                                            alignCenter
                                            justifyBetween
                                            style={{
                                                minHeight: '50px',
                                                border: '1px solid lightgray',
                                                borderRadius: '5px',
                                                paddingLeft: '10px',
                                                paddingRight: '10px',
                                                background: 'white',
                                            }}
                                        >
                                            <Text color={Theme.primaryDark}>{type}</Text>
                                        </Flex>
                                    ))}
                                </Flex>
                            </div>
                        </Flex>
                    </Flex>
                )}

                {/* Navigation & Action Buttons */}
                <Flex justifyEnd style={{ marginTop: step == 1 ? '10px' : '20px' }}>
                    {step == 1 ? (
                        <Button
                            variant="text"
                            style={{ marginRight: '10px' }}
                            onClick={() => close()}
                        >
                            <MeliorTranslate valueKey="Cancel" />
                        </Button>
                    ) : (
                        <Button
                            variant="text"
                            style={{ marginRight: '10px' }}
                            onClick={() => setStep(step - 1)}
                        >
                            <MeliorTranslate valueKey="Back" />
                        </Button>
                    )}
                    {step == maxStep ? (
                        <Button
                            variant="contained"
                            onClick={() => save()}
                            disabled={!isValidStep() || isSaving}
                        >
                            <MeliorTranslate valueKey={mode == 'edit' ? 'Update' : 'Save'} />
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            disabled={!isValidStep()}
                            onClick={() => setStep(step + 1)}
                        >
                            <MeliorTranslate valueKey="Next" />
                        </Button>
                    )}
                </Flex>
            </Box>
        </Modal>
    );
}
