import { useLocation } from 'react-router-dom';
import Container from '../../components/UI/Common/Container';

const VersionPage = () => {
    const location = useLocation();

    return (
        // to be updated every release
        <Container leftOuterSpacing={2}>
            <a href="https://github.com/MeliorAI/Legal-Intelligence-frontend/releases/tag/v1.2.0">
                <h2>
                    FE: {location.state.feVersion} / BE: {location.state.beVersion}
                </h2>
            </a>
            <ol>
                <li>Add Clause-comparison query-param for the insights by @vanessa2020 in #276</li>
                <li>Add ping function to check endpoint availability by @warjohn123 in #277</li>
                <li>Feature/ping requests by @warjohn123 in #278</li>
                <li>Apply word breaks on texts rows by @warjohn123 in #279</li>
                <li>Remove apiBaseUrl by @warjohn123 in #275</li>
                <li>disable create box user in initialize page; by @rockycabaero1 in #281</li>
                <li>update version to v1.0.0-dev by @rockycabaero1 in #280</li>
                <li>Implement be version using config endpoint by @warjohn123 in #285</li>
                <li>Update documents search and filter by @warjohn123 in #284</li>
                <li>handle off-boarding bugs by @rockycabaero1 in #283</li>
                <li>Add Help section in User dropdown by @warjohn123 in #287</li>
                <li>Lint 94 table shrinks some clauses by @vanessa2020 in #288</li>
                <li>Comparison xlsx export by @vanessa2020 in #291</li>
                <li>Add option in date filter to select date range by @warjohn123 in #292</li>
                <li>Add @types/file-saver package by @rockycabaero1 in #293</li>
                <li>
                    Move the navigation container to the header of insights page by @warjohn123 in
                    #289
                </li>
                <li>Fix sorting for other columns in search results by @warjohn123 in #295</li>
                <li>Reorder, hide and show Clauses in context by @vanessa2020 in #296</li>
                <li>Alter search document endpoint call by @warjohn123 in #297</li>
                <li>
                    Add styles on calendar picker to match with date range picker by @warjohn123 in
                    #298
                </li>
                <li>Fix export button not working when in full screen by @warjohn123 in #299</li>
                <li>Comparison & Insights Template Demo Notes by @vanessa2020 in #300</li>
                <li>Update LINT_VERSION to v1.1.0-dev by @rockycabaero1 in #301</li>
                <li>Show hide option for selected insights by @vanessa2020 in #306</li>
                <li>Link admin console by @rockycabaero1 in #305</li>
                <li>
                    Update user session data & show / hide link to Admin Console by @rockycabaero1
                    in #307
                </li>
                <li>
                    Fix: Search Date picker &apos;is between&apos; doesn&apos;t allow to change the
                    year by @vanessa2020 in #310
                </li>
                <li>Allow for documents to be opened in new tabs by @vanessa2020 in #311</li>
                <li>Badge to display ORG status from frontegg APIs by @vanessa2020 in #312</li>
                <li>
                    Back button from insights page not working if accessed via direct link by
                    @vanessa2020 in #313
                </li>
                <li>Websocket to RabbitMQ implementation by @rockycabaero1 in #309</li>
                <li>Setup i18n for internalization by @warjohn123 in #290</li>
                <li>Feature/keydata navigation by @warjohn123 in #314</li>
                <li>WS Support layout updates by @rockycabaero1 in #315</li>
                <li>Feature/keydata navigation by @warjohn123 in #316</li>
                <li>Fix WS connection issues by @rockycabaero1 in #317</li>
                <li>Do not show status text if document is queued by @rockycabaero1 in #318</li>
                <li>Update edit selection or feedback by @warjohn123 in #320</li>
                <li>
                    Display warning sign next to a Clause Insight if no entity was detected by
                    @vanessa2020 in #319
                </li>
                <li>Additional strings to translate by @warjohn123 in #321</li>
                <li>Add progress indicator for file upload by @rockycabaero1 in #325</li>
                <li>
                    getDocumentInsights API call when document is ready by @rockycabaero1 in #323
                </li>
                <li>
                    get insights page progress bar for processing documents by @rockycabaero1 in
                    #324
                </li>
                <li>Fix translations and carousell by @warjohn123 in #326</li>
                <li>
                    Update useWebsocket to include clientId and clean options; by @rockycabaero1 in
                    #327
                </li>
                <li>reset upload button state on error and success by @rockycabaero1 in #328</li>
                <li>Add translation for upload and finalize actions by @rockycabaero1 in #329</li>
                <li>Ctrl+F to search only within the document by @vanessa2020 in #330</li>
                <li>
                    Fix language selected not persisting on insights page by @warjohn123 in #331
                </li>
                <li>Update language in user metadata by @warjohn123 in #332</li>
                <li>Fix multiple extraction into search results table by @warjohn123 in #333</li>
                <li>
                    Remove unnecessary testing dependencies and update packages by @rockycabaero1 in
                    #335
                </li>
                <li>Lint 240 parties navigation by @vanessa2020 in #338</li>
                <li>Stick search to top with fixed width by @vanessa2020 in #339</li>
                <li>Lint 223 document select by @vanessa2020 in #337</li>
                <li>Comment out update language metadata for now by @warjohn123 in #340</li>
                <li>
                    Fix insight being hidden by botom panel in the sidebar by @warjohn123 in #334
                </li>
                <li>
                    Remove localStorage and update language implementation by @warjohn123 in #341
                </li>
            </ol>
        </Container>
    );
};

export default VersionPage;
