import { Text } from '../../UI/Typography';
import { Theme } from '../../../theme';
import Flex from 'styled-flex-component';
import { MeliorTranslate } from '../../MeliorTranslate';

interface Props {
    count: number;
}

export default function SearchResultsCount(props: Props) {
    return (
        <Flex
            alignCenter
            style={{
                backgroundColor: 'rgba(16, 185, 129, 0.1)',
                borderRadius: '50px',
                height: '30px',
                paddingLeft: '10px',
                paddingRight: '10px',
                cursor: 'default',
                border: '0px solid',
                borderColor: Theme.success,
            }}
        >
            <Text color={Theme.success} customFontWeight={500}>
                {props.count}{' '}
                <MeliorTranslate
                    valueKey={props.count > 1 ? 'documents found' : 'document found'}
                />
            </Text>
        </Flex>
    );
}
