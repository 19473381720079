import styled, { css } from 'styled-components';

interface IProps {
    isRepublicHeaderWidgetOpen: boolean;
}

export default styled.div<IProps>`
    background: #e0e0e0;
    height: 100vh;

    ${({ isRepublicHeaderWidgetOpen }: IProps) => css`
        .rpv-core__viewer {
            ${isRepublicHeaderWidgetOpen
                ? `height: calc(100vh - 229px) !important;`
                : `height: calc(100vh - 161px) !important;`}
        }
    `}
`;
