import styled from 'styled-components';

export const StyledDocumentFiltersContainer = styled('div')`
    width: 80%;

    @media (max-width: 1398px) {
        width: 75%;
    }

    @media (max-width: 1254px) {
        width: 67%;
    }

    @media (max-width: 967px) {
        width: 60%;
    }
`;
