import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { fullyRemoveDocument } from '../../../api/files.api';
import { toast } from 'react-toastify';
import { IComparison } from '../../../@types/Comparison';
import { deleteComparisonTask } from '../../../api/comparison.api';
import { MeliorTranslate } from '../../MeliorTranslate';

interface Props {
    comparison: IComparison;
    onClose: () => void;
    onDeleteSuccess: () => void;
    open: boolean;
}

export default function DeleteComparisonDialog(props: Props) {
    const { isLoading } = useMutation(
        ['deleteDocument'],
        async (mutateParams: { documentId: string; boxFileId: string }) => {
            const { boxFileId } = mutateParams;
            try {
                await fullyRemoveDocument(boxFileId);
                toast.success('Document deleted successfully');
            } catch {
                toast.error('Error deleting document.');
            }
        }
    );

    const handleDelete = async () => {
        await deleteComparisonTask(props.comparison.id).then((res) => {
            if (res) {
                toast.success(`Successfully deleted comparison task`);
                props.onDeleteSuccess();
            }
        });
    };

    return (
        <Dialog open={props?.open}>
            <DialogTitle id="alert-dialog-title">
                Are you sure you want to delete{' '}
                <span dangerouslySetInnerHTML={{ __html: props.comparison.name }} />?
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    This action cannot be undone.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    <MeliorTranslate valueKey="Cancel" />
                </Button>
                <Button onClick={handleDelete} autoFocus color="error" disabled={isLoading}>
                    <MeliorTranslate valueKey="Delete" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
