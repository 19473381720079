import CardContainer from '../../../components/UI/Common/Card/Card';
import Container from '../../../components/UI/Common/Container';
import Flex from 'styled-flex-component';
import { Box, Link, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { useSavedQueries } from '../../../hooks/useSavedQueries';
import { TabPanel } from '../../../components/UI/Common/Tabs/Tabs';
import SavedQueriesTable from '../../../components/SavedQueries/Table';
import { Text } from '../../../components/UI/Typography';
import { useTranslation } from 'react-i18next';
import { MeliorTranslate } from '../../../components/MeliorTranslate';
import { MeliorTable } from '../../../components/MeliorTable';
import { PAGINATION_LIMIT } from '../../../constants';

const QueriesPage = () => {
    const { t } = useTranslation();
    const [page, setPage] = useState(1);

    const params = {
        page: page,
        size: PAGINATION_LIMIT,
    };

    const { isLoading, error, savedQueries, totalPages, refetch } = useSavedQueries(params);

    const showLoading = isLoading;

    return (
        <Container>
            <CardContainer>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Flex justifyBetween>
                        <Tabs value={0} style={{ overflow: 'visible' }}>
                            <Tab label={<MeliorTranslate valueKey="Queries" />} />
                        </Tabs>
                    </Flex>
                </Box>
                <TabPanel value={0} index={0}>
                    <MeliorTable
                        showLoading={showLoading}
                        error={error}
                        items={savedQueries}
                        page={page}
                        totalPages={totalPages}
                        setPage={setPage}
                        tableEl={
                            <SavedQueriesTable savedQueries={savedQueries} refetch={refetch} />
                        }
                        loadingMessage="Loading queries"
                        emptyMessage={
                            <Text>
                                {t(
                                    'You have no saved queries but you can search and save your first one from the'
                                )}{' '}
                                <Link href="/client/advanced-search">
                                    <MeliorTranslate valueKey="Advanced Search" />
                                </Link>{' '}
                                <MeliorTranslate valueKey="page" />
                            </Text>
                        }
                        errorMessage="An error has occurred"
                    />
                </TabPanel>
            </CardContainer>
        </Container>
    );
};

export default QueriesPage;
