import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    Switch,
} from '@mui/material';
import { Text } from '../../UI/Typography';
import Flex from 'styled-flex-component';
import { getComparisonExportFile } from '../../../api/comparison.api';
import { useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { DownloadOutlined, Fullscreen, FullscreenExit } from '@mui/icons-material';
import {
    ComparisonDetailContext,
    ComparisonDetailContextType,
} from '../../../pages/Client/ComparisonDetail/context/comparisonDetailContext';
import { GridExpandMoreIcon } from '@mui/x-data-grid-premium';
import DocumentLink from '../../UI/DocumentLink';
import ComparisonResultTable from '../Tables';
import { IDocument } from '../../../@types/Document';
import { useMutation } from '@tanstack/react-query';
import FileSaver from 'file-saver';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

interface IComparisonAccordionProps {
    comparisonId: string;
}

export default function ComparisonAccordion({ comparisonId }: IComparisonAccordionProps) {
    const [isHighlighted, setIsHighlighted] = useState<boolean>(false);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const { comparisonDetails, results } = useContext(
        ComparisonDetailContext
    ) as ComparisonDetailContextType;
    const table = useFullScreenHandle();

    const { mutateAsync, isLoading } = useMutation(['exportFile'], async (comparisonId: string) => {
        try {
            const blob = await getComparisonExportFile(comparisonId);
            FileSaver.saveAs(blob, `${comparisonDetails?.name}.xlsx`);
            toast.success('Comparison exported successfully');
        } catch {
            toast.error('Unable to export comparison.');
        }
    });

    const exportComparison = async () => {
        await mutateAsync(comparisonId as string);
    };

    const reportChange = useCallback(
        (state, handle) => {
            if (handle === table) {
                setIsFullscreen(state);
            }
        },
        [table]
    );
    return (
        <FullScreen handle={table} onChange={reportChange}>
            <Box
                sx={{
                    background: isFullscreen ? 'white' : '',
                    height: isFullscreen ? '100vh' : '',
                    paddingTop: isFullscreen ? '10px' : '0px',
                    overflowY: 'auto',
                }}
            >
                {/* Table Info */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        marginY: isFullscreen ? '0px' : '10px',
                    }}
                >
                    {comparisonDetails?.comparisons && (
                        <Text
                            style={{
                                marginRight: '10px',
                                borderRadius: '50px',
                                background: 'aliceblue',
                                padding: '8px',
                            }}
                        >
                            {comparisonDetails?.comparisons.length} document
                            {comparisonDetails?.comparisons.length > 1 ? 's' : ''} compared
                        </Text>
                    )}
                    <Flex justifyContent alignCenter>
                        <Switch
                            sx={{ marginRight: '5px' }}
                            size="small"
                            checked={isHighlighted}
                            onChange={() => {
                                setIsHighlighted(!isHighlighted);
                            }}
                        />
                        <Text>Highlight Clause Difference</Text>
                    </Flex>
                    {isLoading ? (
                        <div
                            style={{
                                paddingLeft: '15px',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <CircularProgress size={20} />
                            <Text color="black" style={{ marginLeft: '10px' }}>
                                Exporting
                            </Text>
                        </div>
                    ) : (
                        <Button onClick={exportComparison} style={{ marginLeft: '5px' }}>
                            <DownloadOutlined />
                            <Text color="black" style={{ marginLeft: '5px' }}>
                                Export
                            </Text>
                        </Button>
                    )}
                    {!isFullscreen && (
                        <Button onClick={table.enter}>
                            <Fullscreen />{' '}
                            <Text color="black" style={{ marginLeft: '5px' }}>
                                Enter fullscreen
                            </Text>
                        </Button>
                    )}
                    {isFullscreen && (
                        <Button onClick={table.exit}>
                            <FullscreenExit />{' '}
                            <Text color="black" style={{ marginLeft: '5px' }}>
                                Exit fullscreen
                            </Text>
                        </Button>
                    )}
                </Box>
                {/* Results Accordion */}
                <Box
                    sx={{
                        background: '#ffffff',
                        boxShadow: ' 0px 3px 6px rgba(3, 138, 255, 0.1)',
                        borderRadius: '12px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                    }}
                >
                    {comparisonDetails?.comparisons && (
                        <>
                            {comparisonDetails.comparisons.map((comparison: any, index) => (
                                <Accordion
                                    key={index}
                                    elevation={0}
                                    disableGutters
                                    defaultExpanded={index == 0}
                                >
                                    <AccordionSummary
                                        style={{ height: '38px' }}
                                        expandIcon={<GridExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        {results && (
                                            <DocumentLink
                                                document={
                                                    {
                                                        id: results[index].doc_id,
                                                        name: results[index].doc_name,
                                                    } as IDocument
                                                }
                                            />
                                        )}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {results && (
                                            <ComparisonResultTable
                                                comparisonResults={results[index]}
                                                isHighlighted={isHighlighted}
                                            />
                                        )}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </>
                    )}
                </Box>
            </Box>
        </FullScreen>
    );
}
