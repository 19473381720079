import { Badge, Box, Tab, Tabs } from '@mui/material';
import CardContainer from '../../../components/UI/Common/Card/Card';
import Container from '../../../components/UI/Common/Container';
import Flex from 'styled-flex-component';
import { TabPanel } from '../../../components/UI/Common/Tabs/Tabs';
import WorkflowsTable from '../../../components/Workflows/Tables/Workflows';
import { useWorkflows } from '../../../hooks/useWorkflows';
import { Button } from '@mui/material';
import { Text } from '../../../components/UI/Typography';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import WorkflowsTagSelector from '../../../components/UI/WorkflowsTagSelector';
import { useState } from 'react';
import { useLazyEffect } from '../../../hooks/useLazyEffect';
import { useTranslation } from 'react-i18next';
import { MeliorTranslate } from '../../../components/MeliorTranslate';
import { MeliorTable } from '../../../components/MeliorTable';

const WorkflowsPage = () => {
    const { t } = useTranslation();
    const [tags, setTags] = useState<string[]>([]);
    const { isLoading, workflows, error, refetch } = useWorkflows(
        tags.length
            ? {
                  tags: tags.toString(),
              }
            : {},
        'workflows'
    );

    const showLoading = isLoading;

    useLazyEffect(() => {
        refetch();
    }, [tags]);

    return (
        <>
            <Flex alignCenter justifyBetween>
                <WorkflowsTagSelector setTags={setTags} />
                <Button
                    component="label"
                    variant="contained"
                    color="primary"
                    startIcon={<AccountTreeOutlinedIcon />}
                    onClick={() => window.open('https://n8n.melior.ai/workflow/new', '_blank')}
                >
                    <Container>
                        <Text uppercase customletterSpacing={0.05}>
                            <MeliorTranslate valueKey="Workflow Editor" />
                        </Text>
                    </Container>
                </Button>
            </Flex>
            <Container topOuterSpacing={1.3}>
                <CardContainer>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Flex justifyBetween>
                            <Tabs value={0} style={{ overflow: 'visible' }}>
                                <Tab
                                    label={
                                        <Flex alignCenter>
                                            <span>
                                                <MeliorTranslate valueKey="Workflows" />
                                            </span>
                                            {!showLoading && (
                                                <Container leftOuterSpacing={1}>
                                                    <Badge
                                                        badgeContent={workflows.length}
                                                        color="primary"
                                                    />
                                                </Container>
                                            )}
                                        </Flex>
                                    }
                                />
                            </Tabs>
                        </Flex>
                    </Box>
                    <TabPanel value={0} index={0}>
                        <MeliorTable
                            showLoading={showLoading}
                            error={error}
                            items={workflows}
                            tableEl={<WorkflowsTable workflows={workflows} refetch={refetch} />}
                            loadingMessage="Loading workflows"
                            emptyMessage={t('No workflows found')}
                            errorMessage="An error has occurred"
                        />
                    </TabPanel>
                </CardContainer>
            </Container>
        </>
    );
};

export default WorkflowsPage;
