import { Badge, Box, Typography } from '@mui/material';
import { MeliorTranslate } from '../../MeliorTranslate';
import Flex from 'styled-flex-component';
import { Theme } from '../../../theme';
import { useContext } from 'react';
import {
    InsightsTemplateContext,
    InsightsTemplateContextType,
} from '../../../pages/Client/InsightsTemplate/context/insightsTemplateContext';

interface ITemplateListProps {
    setSelectedTemplate: (template: number) => void;
    setHoveredTemplate: (template: number) => void;
}

export default function TemplateList({
    setSelectedTemplate,
    setHoveredTemplate,
}: ITemplateListProps) {
    const { templates, isSearching, selectedTemplate, hoveredTemplate } = useContext(
        InsightsTemplateContext
    ) as InsightsTemplateContextType;
    return (
        <div style={{ height: '80vh', borderRadius: '14px', background: 'white' }}>
            <Box>
                <Flex alignCenter style={{ padding: 30, paddingLeft: 20 }}>
                    <Typography marginRight={2.5}>
                        <MeliorTranslate valueKey="Your Insight Lists" />
                    </Typography>
                    <Badge badgeContent={templates.length} color="primary" />
                </Flex>
                <div
                    style={{
                        maxHeight: '70vh',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }}
                >
                    {!(isSearching && !templates.length) && (
                        <>
                            {templates.map((template: any, index) => (
                                <div
                                    key={index}
                                    style={{
                                        cursor: 'pointer',
                                        background:
                                            selectedTemplate == index
                                                ? Theme.highlight
                                                : hoveredTemplate == index
                                                ? 'whitesmoke'
                                                : 'white',
                                        padding: '20px',
                                        border: '1px solid whitesmoke',
                                        borderBottom: 'none',
                                    }}
                                    onClick={() => setSelectedTemplate(index)}
                                    onMouseEnter={() => setHoveredTemplate(index)}
                                    onMouseLeave={() => setHoveredTemplate(-1)}
                                >
                                    <Typography sx={{ fontSize: 14 }} gutterBottom>
                                        {template.template_name}
                                    </Typography>
                                </div>
                            ))}
                        </>
                    )}
                </div>
            </Box>
        </div>
    );
}
