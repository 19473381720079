import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getUserSessionData, updateUserSessionData } from '../utils/userSession';
import { useToken } from '../hooks/useToken';

export default function Protected() {
    const user = getUserSessionData();
    const token = user?.accessToken;
    const { data } = useToken();
    const location = useLocation();

    if (
        localStorage.getItem('redirectTo') != 'loggedOut' &&
        !location.pathname.includes('insights')
    )
        localStorage.setItem('redirectTo', location.pathname);

    if (user) {
        updateUserSessionData('roles', data?.roles);
    }

    return token ? <Outlet /> : <Navigate to="/login" />;
}
