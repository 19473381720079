import { CloseOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';

interface IRepublicHeaderWidgetProps {
    isRepublicHeaderWidgetOpen: boolean;
    setIsRepublicHeaderWidgetOpen: (val: boolean) => void;
}

export default function RepublicHeaderWidget({
    isRepublicHeaderWidgetOpen,
    setIsRepublicHeaderWidgetOpen,
}: IRepublicHeaderWidgetProps) {
    if (!isRepublicHeaderWidgetOpen) return <></>;
    return (
        <div
            style={{
                position: 'relative',
                width: '100%',
                outline: '1px solid #00f',
                overflow: 'hidden',
                display: 'flex',
                background: 'rgb(0, 73, 255)',
            }}
        >
            <div
                style={{
                    position: 'relative',
                    float: 'left',
                    height: '67px',
                    padding: 0,
                    margin: 0,
                    border: 0,
                    width: '250px',
                }}
            ></div>

            <div
                style={{
                    position: 'relative',
                    float: 'left',
                    height: '54px',
                    padding: 0,
                    margin: 0,
                    border: 0,
                    width: '250px',
                }}
            ></div>
            <div
                style={{
                    position: 'relative',
                    clear: 'both',
                    height: '1px',
                    padding: 0,
                    margin: 0,
                    border: 0,
                    width: '100%',
                }}
            ></div>
            <iframe
                frameBorder="0"
                src="https://republic.com/widgets/header/melior"
                style={{
                    border: 0,
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: '97%',
                    height: '100%',
                    background: '#0049FF',
                }}
            ></iframe>
            <IconButton
                sx={{ marginRight: '6px' }}
                onClick={() => setIsRepublicHeaderWidgetOpen(false)}
            >
                <CloseOutlined sx={{ color: 'white' }} />
            </IconButton>
        </div>
    );
}
