import Container from '../../Common/Container';
import { StyledListItem, StyledUnorderedList } from './Block.styled';
import Flex from 'styled-flex-component';
import { Link, To, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import ContentPasteSearchOutlinedIcon from '@mui/icons-material/ContentPasteSearchOutlined';
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import { SearchOutlined, LocalLibrary, FileOpenOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface SideBarBlockProps {
    isSideBarMaximized: boolean;
}

interface IBlock {
    name: string;
    icon: React.ReactNode;
    slug: To;
    isExpandable?: boolean;
    isDisabled?: boolean;
}

const rootRoute = '/client';

const SideBarBlock = ({ isSideBarMaximized }: SideBarBlockProps) => {
    const location = useLocation();
    const { t } = useTranslation();

    const sidebarBlocks: IBlock[] = [
        // TODO: Uncomment this when dashboard is ready
        // {
        //     name: 'Dashboard',
        //     icon: <DashboardOutlinedIcon />,
        //     slug: `${rootRoute}/dashboard`,
        //     isExpandable: false,
        // },
        {
            name: t('Documents'),
            icon: <DescriptionOutlinedIcon />,
            slug: `${rootRoute}/documents`,
            isExpandable: false,
        },
        {
            name: t('Advanced Search'),
            icon: <SearchOutlined />,
            slug: `${rootRoute}/advanced-search`,
            isExpandable: false,
        },
        {
            name: t('Saved Queries'),
            icon: <QuizOutlinedIcon />,
            slug: `${rootRoute}/saved-queries`,
        },
        {
            name: t('Clause Library'),
            icon: <LocalLibrary />,
            slug: `${rootRoute}/clause-library`,
        },
        {
            name: t('Insight Templates'),
            icon: <ContentPasteSearchOutlinedIcon />,
            slug: `${rootRoute}/insights-template`,
        },
        {
            name: t('Approved Clauses'),
            icon: <FactCheckOutlinedIcon />,
            slug: `${rootRoute}/approved-clauses`,
            isDisabled: true,
        },
        {
            name: t('Comparisons'),
            icon: <CompareArrowsOutlinedIcon />,
            slug: `${rootRoute}/comparisons`,
        },
        {
            name: t('Workflows'),
            icon: <AccountTreeOutlinedIcon />,
            slug: `${rootRoute}/workflows`,
            isExpandable: false,
        },
        {
            name: t('Collaboration'),
            icon: <Groups2OutlinedIcon />,
            slug: `${rootRoute}/collaboration`,
            isDisabled: true,
        },
        {
            name: t('Audit Logs'),
            icon: <FileOpenOutlined />,
            slug: `${rootRoute}/audit-logs`,
            isDisabled: false,
        },
    ];
    const [activeTab, setActiveTab] = useState<To>(sidebarBlocks[0].slug);
    useEffect(() => {
        setActiveTab(location.pathname);
    }, [location]);
    return (
        <section>
            <StyledUnorderedList>
                {sidebarBlocks.map((block: IBlock) => (
                    <StyledListItem
                        isDisabled={block.isDisabled}
                        isActive={activeTab === block.slug}
                        key={block.slug}
                    >
                        <Link to={block.isDisabled ? '#' : block.slug}>
                            <Container className="linkContainer">
                                <Flex alignCenter justifyBetween>
                                    <Flex alignCenter>
                                        <Container rightOuterSpacing={0.9375}>
                                            {block.icon}
                                        </Container>
                                        {isSideBarMaximized && <>{block.name}</>}
                                    </Flex>
                                    {isSideBarMaximized && block.isExpandable && <ExpandMoreIcon />}
                                </Flex>
                            </Container>
                        </Link>
                    </StyledListItem>
                ))}
            </StyledUnorderedList>
        </section>
    );
};

export default SideBarBlock;
