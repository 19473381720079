import { useQuery } from '@tanstack/react-query';
import getTenantAnnouncements from '../api/adminapi/getAnnouncements';

export function useAnnouncementBanner(user) {
    const { data, ...rest } = useQuery(
        ['getallAnnouncements'],
        () => getTenantAnnouncements(user?.tenantId as string),
        {
            refetchOnWindowFocus: false,
        }
    );

    return {
        announcementBanner: data,
        ...rest,
    };
}
