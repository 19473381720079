import apiClient from './apiClient';

interface ISearchPayload {
    query: string | null;
    filters: unknown;
    store: boolean;
    query_name: string | null;
    query_description?: string;
}

export const searchDocuments = async (payload: ISearchPayload) => {
    return apiClient
        .post(`/search`, payload)
        .then((res) => res.data)
        .catch((err) => Promise.reject({ ...err }));
};
