import SearchSectionFilterChip from '../FilterChip';
import Flex from 'styled-flex-component';
import {
    Button,
    FormControl,
    InputAdornment,
    InputLabel,
    ListSubheader,
    MenuItem,
} from '@mui/material';
import { StyledFilterTypeSelector } from './StyledFilterSelect.styled';
import Container from '../../UI/Common/Container';
import { IAdvancedSearchPageSearchFilter } from '../../../pages/Client/AdvancedSearch/Page';
import { Text } from '../../UI/Typography';
import { useContext, useMemo, useState } from 'react';
import { Label, SearchOutlined } from '@mui/icons-material';
import { StyledSearchTextField } from '../SearchSectionInput/StyledTextField';
import { useDocumentTypes } from '../../../hooks/useDocumentTypes';
import { Theme } from '../../../theme';
import {
    AdvancedSearchContext,
    AdvancedSearchContextType,
} from '../../../pages/Client/AdvancedSearch/context/advancedSearchContext';
import { useTranslation } from 'react-i18next';
import TagSelector from '../../UI/TagSelector.tsx';
import { ITagItem } from '../../../@types/Tag';
import { IDocument } from '../../../@types/Document';
import { isEmpty } from 'lodash';
import SearchResultsCount from '../SearchResultsTable/SearchResultsCount';
import { MeliorTranslate } from '../../MeliorTranslate';

interface ISearchFiltersProps {
    setAgreementType: (type: string) => void;
    setFiltersAdded: (clauses: IAdvancedSearchPageSearchFilter[]) => void;
    setIsFiltersOverlayOpened: (val: boolean) => void;
    setSearchText: (val: string) => void;
    setIsAddFilterModalOpen: (val: boolean) => void;
    allTags: ITagItem[];
    setSelectedTags: (tags: string[]) => void;
    selectedTags: string[];
    loadingTags: boolean;
    setIsAddTagOpen: (isOpen: boolean) => void;
    documents: IDocument[] | string[];
    documentsCount: number;
}

const SearchFiltersPageSection = ({
    setAgreementType,
    setFiltersAdded,
    setIsFiltersOverlayOpened,
    setSearchText,
    setIsAddFilterModalOpen,
    allTags,
    setSelectedTags,
    selectedTags,
    loadingTags,
    setIsAddTagOpen,
    documents,
    documentsCount,
}: ISearchFiltersProps) => {
    const { t } = useTranslation();
    const [selectSearchText, setSelectSearchText] = useState('');
    const { filtersAdded, agreementType, setSelectedClauseIndex } = useContext(
        AdvancedSearchContext
    ) as AdvancedSearchContextType;

    const containsText = (text, searchText) =>
        text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    const { docTypes } = useDocumentTypes();

    const displayedOptions = useMemo(
        () =>
            docTypes &&
            ['All'].concat(docTypes).filter((option) => containsText(option, selectSearchText)),
        [selectSearchText, docTypes]
    );
    const handleChange = (e) => {
        const selectedOption = e.target.value;

        if (selectedOption === 'All') return;

        const clause = filtersAdded.find((filter) => filter.filterLabel === selectedOption);
        if (!clause) {
            setFiltersAdded([
                ...filtersAdded,
                {
                    filterLabel: 'Type',
                    value: e.target.value,
                    display: `is ${e.target.value}`,
                },
            ]);
        }
        setAgreementType('');
    };

    const clearFilters = () => {
        setFiltersAdded([]);
        setSearchText('');
        setSelectedTags([]);
    };

    const onFilterRemove = (index: number) => {
        const temp = [...filtersAdded];
        temp.splice(index, 1);
        setFiltersAdded(temp);
    };

    const removeFilter = (index: number) => {
        if (filtersAdded[index].filterLabel == 'Tag') {
            const tags = selectedTags.filter((tag) => tag != filtersAdded[index].value);
            setSelectedTags(tags);
        }
        onFilterRemove(index);
    };

    const addTagsToFilter = (tags) => {
        let filters = filtersAdded.concat([]);
        filters = filters.filter(
            (filter) => filter.filterLabel != 'Tag' || tags.includes(filter.value)
        );

        const newlyAdded = filters;
        tags.filter((tag) => !filters.map((filter) => filter.value).includes(tag)).forEach(
            (tag) => {
                newlyAdded.push({
                    filterLabel: 'Tag',
                    value: tag,
                    display: tag,
                });
            }
        );

        setFiltersAdded(newlyAdded);
        setSelectedTags(tags);
    };
    return (
        <div>
            {Boolean(filtersAdded.length) && (
                <Container
                    outerSpacing={0.25}
                    style={{
                        maxHeight: '150px',
                        background: 'whitesmoke',
                        padding: '10px',
                        overflowY: 'auto',
                        borderRadius: '10px',
                    }}>
                    <Flex justifyEvenly alignCenter wrap row>
                        {filtersAdded.map((clause, index) => (
                            <Container
                                key={index}
                                rightOuterSpacing={0.5625}
                                topOuterSpacing={0.25}
                                bottomOuterSpacing={0.25}>
                                <SearchSectionFilterChip
                                    index={index}
                                    onFilterRemove={(index) => removeFilter(index)}
                                    clause={clause}
                                    setIsFiltersOverlayOpened={setIsFiltersOverlayOpened}
                                    setSelectedClauseIndex={setSelectedClauseIndex}
                                    color={
                                        clause.filterLabel == 'Tag'
                                            ? allTags
                                                ? allTags.find((tag) => tag.name == clause.value)
                                                      ?.color
                                                : ''
                                            : ''
                                    }
                                />
                            </Container>
                        ))}
                    </Flex>
                </Container>
            )}
            {/* Search Options */}
            <Flex justifyBetween style={{ marginTop: '10px' }}>
                {/* Filters */}
                <Flex alignCenter style={{ gap: '10px' }}>
                    {/* Document Type */}
                    <FormControl
                        size="small"
                        sx={{ borderColor: 'rgba(16, 185, 129, 0.3)', width: '200px' }}>
                        <InputLabel
                            shrink={false}
                            sx={{
                                color: `${Theme.primaryDark} !important`,
                                fontSize: '13px',
                                marginTop: '-3px',
                            }}>
                            <MeliorTranslate valueKey="Document Type" />
                        </InputLabel>
                        <StyledFilterTypeSelector
                            style={{ fontSize: '13px', height: '32px' }}
                            value={agreementType}
                            onChange={handleChange}>
                            <ListSubheader>
                                <StyledSearchTextField
                                    size="small"
                                    autoComplete="off"
                                    placeholder="Start typing"
                                    fullWidth
                                    sx={{
                                        background: 'rgba(0, 0, 0, 0.06)',
                                    }}
                                    value={selectSearchText}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchOutlined />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(e) => setSelectSearchText(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key !== 'Escape') {
                                            // Prevents autoselecting item while typing (default Select behaviour)
                                            e.stopPropagation();
                                        }
                                    }}
                                />
                            </ListSubheader>
                            {displayedOptions &&
                                displayedOptions.length &&
                                displayedOptions.map((clause, index) => (
                                    <MenuItem sx={{ fontSize: 14 }} key={index} value={clause}>
                                        {clause}
                                    </MenuItem>
                                ))}
                        </StyledFilterTypeSelector>
                    </FormControl>
                    {/* Tag */}
                    <TagSelector
                        allTags={allTags}
                        selectedTags={selectedTags}
                        setSelectedTags={(tags) => addTagsToFilter(tags)}
                        showLabel={false}
                        height="32px"
                        loadingTags={loadingTags}
                        showBalloons={false}
                    />
                    {/* Clauses */}
                    <Button
                        onClick={() => setIsAddFilterModalOpen(true)}
                        variant="outlined"
                        style={{ height: '32px' }}>
                        <Text customFontFamily="Poppins" customFontWeight={500} uppercase>
                            <MeliorTranslate valueKey="Add Filter" />
                        </Text>
                    </Button>
                    {/* Clear */}
                    {Boolean(filtersAdded.length || selectedTags.length) && (
                        <Button onClick={clearFilters}>
                            <Text customFontFamily="Poppins" customFontWeight={500} uppercase>
                                <MeliorTranslate valueKey="Clear All" />
                            </Text>
                        </Button>
                    )}
                </Flex>
                {/* Result Actions */}
                {!isEmpty(documents) && (
                    <Flex alignCenter style={{ gap: '10px' }}>
                        <SearchResultsCount count={documentsCount} />
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setIsAddTagOpen(true);
                            }}>
                            <Label sx={{ marginRight: 1 }} />
                            {t('Tag')} {documents.length}{' '}
                            <MeliorTranslate
                                valueKey={documents.length > 1 ? 'Documents' : 'Document'}
                            />
                        </Button>
                    </Flex>
                )}
            </Flex>
        </div>
    );
};

export default SearchFiltersPageSection;
