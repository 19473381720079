import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Text } from '../../../UI/Typography';
import WorkflowsTableItem from './Item';
import { IWorkflow } from '../../../../@types/Workflow';
import { MeliorTranslate } from '../../../MeliorTranslate';

interface IWorkflowsTableProps {
    workflows: IWorkflow[];
    refetch: () => void;
}

const WorkflowsTable = ({ workflows, refetch }: IWorkflowsTableProps) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: '40%' }}>
                            <Text customFontWeight={700}>
                                <MeliorTranslate valueKey="Name" />
                            </Text>
                        </TableCell>
                        <TableCell style={{ width: '40%' }}>
                            <Text customFontWeight={700}>
                                <MeliorTranslate valueKey="Tags" />
                            </Text>
                        </TableCell>
                        <TableCell style={{ width: '10%' }}>&nbsp;</TableCell>
                        <TableCell style={{ width: '5%' }}>&nbsp;</TableCell>
                        <TableCell style={{ width: '5%' }}>&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {workflows.map((item: IWorkflow, index) => (
                        <WorkflowsTableItem
                            key={item.id}
                            workflow={item}
                            index={index}
                            onDeleteSuccess={refetch}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default WorkflowsTable;
