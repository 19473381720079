import { Chip, Tooltip } from '@mui/material';

interface IDocumentTagChipProps {
    label: string;
    title: string | null;
    onClick: () => void;
    color: string;
}

const DocumentTagChip = ({ label, title, onClick, color }: IDocumentTagChipProps) => {
    return (
        <span onClick={onClick}>
            <Tooltip title={title}>
                <Chip
                    label={label}
                    size="small"
                    style={{
                        marginRight: '5px',
                        cursor: 'pointer',
                        marginTop: '7px',
                        maxWidth: '100px',
                        backgroundColor: color,
                    }}
                />
            </Tooltip>
        </span>
    );
};

export default DocumentTagChip;
