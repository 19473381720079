import { useEffect, useState } from 'react';
import SearchSectionInput from '../../../components/AdvancedSearch/SearchSectionInput/SearchSectionInput';
import { searchDocuments } from '../../../api/search.api';
import { IDocument } from '../../../@types/Document';
import SearchFiltersPageSection from '../../../components/AdvancedSearch/SearchFiltersPageSection';
import SearchResultsTable from '../../../components/AdvancedSearch/SearchResultsTable';
import SearchFiltersOverlayList from '../../../components/AdvancedSearch/SearchFiltersOverlayList';
import Container from '../../../components/UI/Common/Container';
import CardContainer from '../../../components/UI/Common/Card/Card';
import { formFiltersPayload, formQueryText, getFilterParams, queryStr } from './helpers';
import { useLazyEffect } from '../../../hooks/useLazyEffect';
import AddFilterModal from '../../../components/AdvancedSearch/AddFilterModal/AddFilterModal';
import Flex from 'styled-flex-component';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { Text } from '../../../components/UI/Typography';
import SaveQueryModal from '../../../components/AdvancedSearch/SaveQueryModal';
import { useSearchParams } from 'react-router-dom';
import AdvancedSearchProvider from './context/advancedSearchContext';
import { getTags } from '../../../api/tags';
import { tagsToArray } from '../../../utils/transformTags';
import { ITagItem } from '../../../@types/Tag';
import AddTagModal from '../../../components/Documents/AddTagModal';
import { isEmpty } from 'lodash';
import { SaveOutlined } from '@mui/icons-material';
import { MeliorTranslate } from '../../../components/MeliorTranslate';

export interface QType {
    type: string;
    unit: string;
    value: string;
}

interface SearchResponse {
    q_type: QType | null;
    matching_docs: IDocument[];
    matching_clauses: string[];
}

export interface IAdvancedSearchPageSearchFilter {
    filterLabel: string;
    value?:
        | string
        | {
              [operator: string]: string;
          };
    display?: string;
}

const AdvancedSearchPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [documents, setDocuments] = useState<IDocument[]>([]);

    const [isSaveQueryModalOpen, setIsSaveQueryModalOpen] = useState<boolean>(false);

    const [isFiltersOverlayOpened, setIsFiltersOverlayOpened] = useState<boolean>(false);

    const [searchText, setSearchText] = useState<string>('');
    const [agreementType, setAgreementType] = useState<string>('');
    const [isExactMatch, setIsExactMatch] = useState<boolean>(true);
    const [filtersAdded, setFiltersAdded] = useState<IAdvancedSearchPageSearchFilter[]>([]);
    const [matchingClauses, setMatchingClauses] = useState<string[]>([]);
    const [matchingType, setMatchingType] = useState<QType | null>(null);
    const [isAddFilterModalOpen, setIsAddFilterModalOpen] = useState<boolean>(false);
    const [allTags, setAllTags] = useState<Array<ITagItem>>([]);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [loadingTags, setLoadingTags] = useState<boolean>(true);
    const [isAddTagOpen, setIsAddTagOpen] = useState(false);
    const [documentTags, setDocumentTags] = useState<object>({});
    const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);

    useEffect(() => {
        getAllTags();
    }, []);

    const onSearch = async () => {
        setSearchParams(queryStr(searchText, filtersAdded));
        setIsLoading(true);
        try {
            const response: SearchResponse = await searchDocuments({
                query: searchText ? formQueryText(searchText, isExactMatch) : '',
                filters: formFiltersPayload(filtersAdded),
                store: false,
                query_name: '',
            });
            setDocuments(response.matching_docs);
            getDocumentTags();
            getAllTags();
            setMatchingClauses(response.matching_clauses);
            setMatchingType(response.q_type);
            setIsLoading(false);
        } catch (e) {
            resetResults();
            setIsLoading(false);
        }
    };

    const getDocumentTags = () => {
        let tagObject = {};
        documents.forEach((doc) => {
            tagObject = { ...tagObject, ...doc.tags };
        });
        setDocumentTags(tagObject);
    };

    async function onCreateQuery(queryName, description) {
        await searchDocuments({
            query: searchText ? formQueryText(searchText, isExactMatch) : '',
            filters: formFiltersPayload(filtersAdded),
            store: true,
            query_name: queryName,
            query_description: description,
        });
    }

    const onAddFilters = (filters: string[]) => {
        const tempList: { filterLabel: string }[] = [];
        const currFilters = filtersAdded.filter((filter) => filters.includes(filter.filterLabel));

        for (let i = 0; i < filters.length; i++) {
            const clause = currFilters.find((filter) => filter.filterLabel === filters[i]);
            if (!clause) {
                tempList.push({
                    filterLabel: filters[i],
                });
            }
        }

        setFiltersAdded([...currFilters, ...tempList]);
        setIsAddFilterModalOpen(false);
    };

    useLazyEffect(() => {
        if (hasSearchParams()) onSearch();
        else resetResults();
    }, [filtersAdded, searchText, agreementType, isExactMatch]);

    const hasSearchParams = () => {
        return (
            !isEmpty(filtersAdded) ||
            !isEmpty(searchText) ||
            !isEmpty(agreementType) ||
            !isEmpty(isExactMatch)
        );
    };

    const resetResults = () => {
        setDocuments([]);
        getDocumentTags();
        setMatchingClauses([]);
        setMatchingType(null);
    };

    const getAllTags = () => {
        getTags().then((res) => {
            setAllTags(tagsToArray(res.tags) as any);
            setLoadingTags(false);
        });
    };

    //initializes persistence
    useEffect(() => {
        if (searchParams.get('query')) {
            setSearchText(searchParams.get('query') ?? '');
        }

        if (searchParams.get('type')) {
            setAgreementType(searchParams.get('type') ?? '');
        }

        if (searchParams.get('filters') && searchParams.get('filters')?.length) {
            const searchParamsFilters = searchParams.get('filters')?.split(',');
            if (!searchParamsFilters?.length) return;

            const filters = getFilterParams(searchParamsFilters);
            setFiltersAdded(filters);
        }
    }, []);

    return (
        <AdvancedSearchProvider
            searchText={searchText}
            filtersAdded={filtersAdded}
            matchingClauses={matchingClauses}
            agreementType={agreementType}
        >
            <Container>
                <CardContainer>
                    <Container bottomOuterSpacing={2}>
                        <Flex justifyBetween>
                            <SearchSectionInput
                                matchingDocuments={documents}
                                matchingType={matchingType}
                                setFiltersAdded={setFiltersAdded}
                                setSearchText={(text) => {
                                    if (text.length >= 3 || text.length == 0) setSearchText(text);
                                }}
                            />
                            <Button
                                variant="contained"
                                disabled={!(filtersAdded.length || searchText)}
                                startIcon={<SaveOutlined />}
                                onClick={() => setIsSaveQueryModalOpen(true)}
                            >
                                <Text uppercase customletterSpacing={0.05}>
                                    <MeliorTranslate valueKey="Save Query" />
                                </Text>
                            </Button>

                            <SaveQueryModal
                                isOpen={isSaveQueryModalOpen}
                                isExactMatch={isExactMatch}
                                setIsOpen={setIsSaveQueryModalOpen}
                                onSave={onCreateQuery}
                            />
                        </Flex>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    onChange={(e) => setIsExactMatch(e.target.checked)}
                                    value={isExactMatch}
                                    color="default"
                                    defaultChecked
                                />
                            }
                            label={
                                <Text
                                    customFontSize={0.8125}
                                    color="rgba(13, 24, 50, 0.54);"
                                    customletterSpacing={0}
                                >
                                    <MeliorTranslate valueKey="exact matches only" />
                                </Text>
                            }
                        />
                        <SearchFiltersPageSection
                            setAgreementType={setAgreementType}
                            setFiltersAdded={setFiltersAdded}
                            setSearchText={setSearchText}
                            setIsFiltersOverlayOpened={setIsFiltersOverlayOpened}
                            setIsAddFilterModalOpen={setIsAddFilterModalOpen}
                            setSelectedTags={setSelectedTags}
                            selectedTags={selectedTags}
                            allTags={allTags}
                            loadingTags={loadingTags}
                            documents={selectedDocuments.length ? selectedDocuments : documents}
                            setIsAddTagOpen={setIsAddTagOpen}
                            documentsCount={documents.length}
                        />
                    </Container>
                    <SearchResultsTable
                        documents={documents}
                        isLoading={isLoading}
                        setSelectedDocuments={setSelectedDocuments}
                    />
                    <SearchFiltersOverlayList
                        isFiltersOverlayOpened={isFiltersOverlayOpened}
                        setFiltersAdded={setFiltersAdded}
                        setIsFiltersOverlayOpened={setIsFiltersOverlayOpened}
                        setIsAddFilterModalOpen={setIsAddFilterModalOpen}
                    />
                    <AddFilterModal
                        isOpen={isAddFilterModalOpen}
                        setIsOpen={setIsAddFilterModalOpen}
                        onAddFilters={onAddFilters}
                    />
                    <AddTagModal
                        isOpen={isAddTagOpen}
                        setIsOpen={() => setIsAddTagOpen(!isAddTagOpen)}
                        onSaveTagsSuccess={() => {
                            setIsAddTagOpen(false);
                        }}
                        documentIds={
                            isEmpty(selectedDocuments)
                                ? documents.map((doc) => doc.id)
                                : selectedDocuments
                        }
                        documentTags={documentTags}
                        mode={'adding'}
                    />
                </CardContainer>
            </Container>
        </AdvancedSearchProvider>
    );
};

export default AdvancedSearchPage;
