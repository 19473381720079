import {
    Autocomplete,
    Badge,
    Box,
    Button,
    Chip,
    Modal,
    TextField,
    createFilterOptions,
} from '@mui/material';
import { H5, Text } from '../../UI/Typography';
import Container from '../../UI/Common/Container';
import Flex from 'styled-flex-component';
import { CloseOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../../theme';
import { ITag, ITagItem } from '../../../@types/Tag';
import { addTags, deleteTags, getTags } from '../../../api/tags';
import { toast } from 'react-toastify';
import { MuiColorInput } from 'mui-color-input';
import { tagArrayToObject, tagsToArray } from '../../../utils/transformTags';
import { isEmpty } from 'lodash';
import { MeliorTranslate } from '../../MeliorTranslate';
import HelpIcon from '@mui/icons-material/Help';
import { styled } from '@material-ui/core';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import ConfirmDialog from '../../UI/Common/ConfirmDialog';

interface IAddTagModalProps {
    documentTags: object;
    documentIds: string[];
    isOpen: boolean;
    setIsOpen: (val: boolean) => void;
    onSaveTagsSuccess: (tags: ITagItem[], action: string) => void;
    mode: string;
    allTags?: ITagItem[];
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    p: 4,
    borderRadius: '10px',
};

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 200,
    },
});

export default function AddTagModal({
    isOpen,
    documentTags,
    documentIds,
    setIsOpen,
    onSaveTagsSuccess,
    mode,
}: IAddTagModalProps) {
    const defaultTagColor = '#ADD8E6';
    const { t } = useTranslation();
    const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState('');
    const [tagColor, setTagColor] = useState(defaultTagColor);
    const [tags, setTags] = useState<Array<ITagItem>>([]);
    const [deletedTags, setDeletedTags] = useState<string[]>([]);
    const [tag, setTag] = useState<string>('');
    const [highlightedTag, setHighlightedTag] = useState<string>('');
    const [allDocTags, setAllDocTags] = useState<Array<ITagItem>>([]);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [isTagNameValid, setIsTagNameValid] = useState<boolean>(true);

    const filterOptions = createFilterOptions({
        ignoreCase: false,
    });

    useEffect(() => {
        setTags(mode == 'adding' ? [] : tagsToArray(documentTags));
    }, [mode, documentTags]);

    useEffect(() => {
        getAllTags();
    }, []);

    const getAllTags = () => {
        getTags().then((res) => {
            setAllDocTags(tagsToArray(res.tags));
        });
    };

    const scrollToDisplayBottom = () => {
        const element: any = document.getElementById('displayed-tags-bottom');
        element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    };

    const saveTags = () => {
        let hasAlreadyShowUpdateMsg = false;
        if (hasAdded()) {
            const tagList: ITag = { doc_ids: documentIds, tags: tagArrayToObject(tags) };
            addTags(tagList).then((res) => {
                if (res) {
                    toast.success(`Tags ${mode == 'updating' ? 'updated' : 'saved'} successfully.`);
                    hasAlreadyShowUpdateMsg = true;
                    onSaveTagsSuccess(tags, 'add');
                    if (!hasDeleted()) resetModal();
                } else {
                    toast.error(t(`Error saving tags.`));
                }
            });
        }
        if (hasDeleted()) {
            const tagList: ITag = { doc_ids: documentIds, tags: deletedTags };
            deleteTags(tagList).then((res) => {
                if (res) {
                    if (!hasAlreadyShowUpdateMsg) {
                        toast.success(
                            `Tags ${mode == 'updating' ? 'updated' : 'deleted'} successfully.`
                        );
                    }
                    onSaveTagsSuccess(
                        deletedTags.map((tag) => {
                            return { name: tag, color: '' };
                        }),
                        'delete'
                    );
                    resetModal();
                } else {
                    toast.error(t(`Error deleting tags.`));
                }
            });
        }
    };

    const hasDeleted = () => {
        return Boolean(deletedTags.length);
    };

    const hasAdded = () => {
        if (!tags.length) return false;

        //check if deleted tags have not been reinputted
        const docTags =
            mode == 'adding' ? [] : tagsToArray(documentTags).map((docTag) => docTag.name);
        const newTags = tags.filter((tag) => !docTags.includes(tag.name));

        return Boolean(newTags.length);
    };

    const setCurrTag = (tag) => {
        setTag(tag);
        setIsDuplicate(tags.map((newTag) => newTag.name).includes(tag));
        setInputValue(tag);

        // if existing, set tag's color
        const isExistingTag = allDocTags.find((docTag) => docTag.name === tag);
        setTagColor(
            isExistingTag
                ? allDocTags.find((existingTag) => existingTag.name == tag)?.color ?? tagColor
                : tagColor
        );
    };

    const cancel = () => {
        if (hasAdded() || hasDeleted()) {
            setShowConfirm(true);
        } else {
            closeModal();
        }
    };

    const closeModal = () => {
        setIsOpen(false);
        resetModal();
    };

    const resetModal = () => {
        setTagColor(defaultTagColor);
        setInputValue('');
        setIsTagNameValid(true);
        setIsDuplicate(false);
        setDeletedTags([]);
        setShowConfirm(false);
        setTags(mode == 'adding' ? [] : tagsToArray(documentTags));
        getAllTags();
    };

    const isExistingTag = (tag: string) => {
        return tagsToArray(documentTags)
            .map((tag) => tag.name)
            .includes(tag);
    };

    const sanitizeDeleted = (tag: string) => {
        const index = deletedTags.findIndex((deleted) => deleted == tag);
        if (index > -1) deletedTags.splice(index, 1);
    };

    return (
        <Modal open={isOpen} aria-labelledby="add-tag-title" aria-describedby="add-tag-description">
            <Box sx={style}>
                <Flex justifyBetween alignTop>
                    <Flex alignCenter>
                        <H5>
                            <MeliorTranslate
                                valueKey={
                                    mode == 'updating'
                                        ? 'Edit Tags'
                                        : mode == 'adding'
                                          ? 'Add Tags'
                                          : 'Remove Tags'
                                }
                            />
                        </H5>
                        <Container leftOuterSpacing={1.5}>
                            <Badge badgeContent={tags.length} color="primary" />
                        </Container>
                    </Flex>
                    <Container onClick={() => cancel()}>
                        <CloseOutlined sx={{ color: 'rgba(0, 0, 0, 0.54)', cursor: 'pointer' }} />
                    </Container>
                </Flex>
                <div
                    style={{
                        border: '1px dashed gray',
                        height: '100px',
                        marginBottom: '10px',
                        marginTop: '10px',
                    }}
                >
                    {!isEmpty(tags) && (
                        <div
                            style={{
                                height: tags.length == 1 ? '50px' : '80px',
                                overflow: 'auto',
                                overflowX: 'hidden',
                                padding: '10px',
                            }}
                        >
                            {tags.map((tag, index) => (
                                <Tooltip title={tag.name.length > 30 ? tag.name : null} key={index}>
                                    <Chip
                                        label={tag.name}
                                        onDelete={() => {
                                            setTags(
                                                tags.filter((existingTag) => existingTag != tag)
                                            );
                                            if (isExistingTag(tag.name))
                                                setDeletedTags(deletedTags.concat(tag.name));
                                        }}
                                        style={{
                                            marginRight: '2px',
                                            marginBottom: '5px',
                                            backgroundColor: tag.color,
                                            maxWidth: '200px',
                                        }}
                                    />
                                </Tooltip>
                            ))}
                            <div id="displayed-tags-bottom" />
                        </div>
                    )}
                    {tags.length < 2 && mode !== 'deleting' && (
                        <Flex
                            justifyCenter
                            alignCenter
                            column
                            style={{ height: tags.length == 0 ? '100%' : '20%' }}
                        >
                            <Text
                                color="gray"
                                style={{
                                    width: '60%',
                                    textAlign: 'center',
                                }}
                                customFontSize={0.8}
                            >
                                <MeliorTranslate
                                    valueKey={
                                        tags.length == 0
                                            ? 'Select a color, input your tag key, then press Enter'
                                            : 'Add as many tags as you need'
                                    }
                                />
                            </Text>
                        </Flex>
                    )}
                    <div
                        style={{
                            zIndex: 1051,
                            position: 'fixed',
                            top: 140,
                            right: 40,
                            width: '25px',
                        }}
                    >
                        <CustomWidthTooltip
                            title={`Update your list by ${mode == 'deleting' ? 'removing unused tags' : `adding new tags${!isEmpty(tags) ? ' and removing unused ones' : ''}`}. Click 'Apply All Tags' to Save.`}
                        >
                            <HelpIcon style={{ color: 'darkgray' }} />
                        </CustomWidthTooltip>
                    </div>
                </div>

                {mode !== 'deleting' && (
                    <Flex alignTop justifyCenter style={{ marginBottom: '20px' }}>
                        <Tooltip title={t('Set tag color')}>
                            <MuiColorInput
                                format="hex"
                                value={tagColor}
                                onChange={setTagColor}
                                style={{ width: '14%', marginRight: '10px' }}
                            />
                        </Tooltip>
                        <Autocomplete
                            filterOptions={filterOptions}
                            disablePortal
                            options={allDocTags.map((tag) => tag.name) ?? []}
                            sx={{ width: '87%', marginTop: '2px' }}
                            freeSolo={true}
                            value={inputValue}
                            onHighlightChange={(e, option) => {
                                setHighlightedTag(option as string);
                            }}
                            onChange={(e, newValue) => {
                                setCurrTag(newValue ?? '');
                                setHighlightedTag('');
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    sx={{
                                        input: { color: isDuplicate ? 'red' : Theme.primaryDark },
                                    }}
                                    onChange={(e) => {
                                        const reg = new RegExp(/^[^.]*$/); //prevent tag names having decimal or period
                                        setIsTagNameValid(reg.test(e.target.value));
                                        setCurrTag(e.target.value);
                                        setHighlightedTag('');
                                    }}
                                    error={!isTagNameValid}
                                    helperText={
                                        !isTagNameValid ? 'Invalid character/symbol "."' : ''
                                    }
                                    onKeyDown={(e) => {
                                        if (!isTagNameValid) return;
                                        if (e.key === 'Enter') {
                                            if (
                                                !isEmpty(highlightedTag) &&
                                                highlightedTag !== tag
                                            ) {
                                                setCurrTag(highlightedTag);
                                                setHighlightedTag('');
                                                return;
                                            }
                                            if (!isDuplicate && /\S/.test(tag)) {
                                                setTags(
                                                    tags.concat({ name: tag, color: tagColor })
                                                );
                                                sanitizeDeleted(tag);
                                                setInputValue('');
                                                setCurrTag('');
                                                if (tags.length) scrollToDisplayBottom();
                                            }
                                        }
                                    }}
                                    placeholder={t('Enter a new tag')}
                                />
                            )}
                        />
                    </Flex>
                )}
                <Flex justifyEnd>
                    <Button variant="text" style={{ marginRight: '10px' }} onClick={() => cancel()}>
                        <MeliorTranslate valueKey="Cancel" />
                    </Button>
                    <Button
                        variant="contained"
                        disabled={!hasDeleted() && !hasAdded()}
                        onClick={() => saveTags()}
                    >
                        <MeliorTranslate valueKey="Apply all tags" />
                    </Button>
                </Flex>
                <ConfirmDialog
                    isOpen={showConfirm}
                    title="Are you sure you want to cancel?"
                    message="All of your changes will be lost."
                    onCancel={() => {
                        setShowConfirm(false);
                    }}
                    onConfirm={() => {
                        closeModal();
                    }}
                />
            </Box>
        </Modal>
    );
}
