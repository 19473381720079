import Container from '../Common/Container';
import SidebarStyled from './Sidebar.styled';
import sidebarFullLogo from '../../../images/sidebar-full-logo.png';
import sidebarIconLogo from '../../../images/sidebar-icon-logo.png';
import SideBarBlock from './Block';
import ChevronToggleButton from './ChevronToggle';
import VersionNumber from '../VersionNumber/VersionNumber';

interface SidebarProps {
    isSideBarMaximized: boolean;
    isSmallerScreen: boolean;
    isRepublicHeaderWidgetOpen: boolean;
    setIsSideBarMaximized: (val: boolean) => void;
}

const Sidebar = ({
    isSideBarMaximized,
    isSmallerScreen,
    isRepublicHeaderWidgetOpen,
    setIsSideBarMaximized,
}: SidebarProps) => {
    return (
        <SidebarStyled
            isSideBarMaximized={isSideBarMaximized}
            isRepublicHeaderWidgetOpen={isRepublicHeaderWidgetOpen}
        >
            <Container backgroundColor={'rgba(255, 255, 255, 0.04);'} innerSpacing={1.5}>
                {isSideBarMaximized && <img alt="logo" src={sidebarFullLogo} />}

                {!isSideBarMaximized && <img alt="logo" src={sidebarIconLogo} />}

                {/* <Container absolute rightPosition={-0.5} topPosition={1.3}></Container> */}
                <ChevronToggleButton
                    isSideBarMaximized={isSideBarMaximized}
                    disabled={isSmallerScreen}
                    onClick={() => setIsSideBarMaximized(!isSideBarMaximized)}
                    style={{
                        position: 'absolute',
                        right: -10,
                        zIndex: 7,
                    }}
                />
            </Container>
            <Container innerSpacing={0.5}>
                <SideBarBlock isSideBarMaximized={isSideBarMaximized} />
            </Container>
            <VersionNumber />
        </SidebarStyled>
    );
};

export default Sidebar;
