import { createContext } from 'react';
import { ITemplate } from '../../../../@types/Template';

export type InsightsTemplateContextType = {
    templates: ITemplate[];
    initialTemplates: ITemplate[];
    hoveredTemplate: number;
    selectedTemplate: number;
    isLoadingTypes: boolean;
    allClauses: string[];
    hiddenClauses: string[];
    isDeleting: boolean;
    isSearching: boolean;
    isOpen: boolean;
    mode: string;
};

export const InsightsTemplateContext = createContext<InsightsTemplateContextType | null>(null);

interface InsightsTemplateProviderProps {
    children: React.ReactNode;
    templates: ITemplate[];
    initialTemplates: ITemplate[];
    hoveredTemplate: number;
    selectedTemplate: number;
    isLoadingTypes: boolean;
    allClauses: string[];
    hiddenClauses: string[];
    isDeleting: boolean;
    isSearching: boolean;
    isOpen: boolean;
    mode: string;
}

const InsightsTemplateProvider = ({
    children,
    templates,
    initialTemplates,
    hoveredTemplate,
    selectedTemplate,
    isLoadingTypes,
    allClauses,
    hiddenClauses,
    isDeleting,
    isSearching,
    isOpen,
    mode,
}: InsightsTemplateProviderProps) => {
    return (
        <InsightsTemplateContext.Provider
            value={{
                templates,
                initialTemplates,
                hoveredTemplate,
                selectedTemplate,
                isLoadingTypes,
                allClauses,
                hiddenClauses,
                isDeleting,
                isSearching,
                isOpen,
                mode,
            }}
        >
            {children}
        </InsightsTemplateContext.Provider>
    );
};

export default InsightsTemplateProvider;
