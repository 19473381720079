import axios from 'axios';
import { getUserSessionData } from '../utils/userSession';
import { getUserConfig } from '../utils/userConfig';

const user = getUserSessionData();

const backendAPIClient = axios.create();

const interceptor = (config) => {
    const backendBaseURL = getUserConfig()?.backendBaseUrl;
    config.baseURL = backendBaseURL ? backendBaseURL : config.baseURL;
    config.headers.Authorization = `Bearer ${user?.accessToken}`;
    return config;
};

backendAPIClient.interceptors.request.use(interceptor);

export default backendAPIClient;
