import { useQuery } from '@tanstack/react-query';
import { fetchComparisons } from '../api/comparison.api';

export function useComparisons(params) {
    const { data, ...rest } = useQuery({
        queryFn: () => fetchComparisons(params).then((res) => res),
        queryKey: ['comparisons', params],
        refetchOnWindowFocus: false,
    });

    return {
        comparisons: data?.items,
        totalPages: data?.pages,
        ...rest,
    };
}
