import { IHighlight } from './types';

export function getChatHighlights(chunkBox, pageIndex) {
    if (!chunkBox.length) return [];

    const highlights: IHighlight[] = [];

    chunkBox.map((bboxArr) => {
        if (bboxArr.length) {
            const left = bboxArr[0];
            const top = bboxArr[1];
            const width = bboxArr[2];
            const height = bboxArr[3];
            highlights.push({
                pageIndex,
                height,
                left,
                top,
                width,
                text: '',
                isSelected: true,
            });
        }
    });

    return highlights;
}
