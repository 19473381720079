import CardContainer from '../../../components/UI/Common/Card/Card';
import Container from '../../../components/UI/Common/Container';
import Flex from 'styled-flex-component';
import { Box, Tab, Tabs } from '@mui/material';
import { TabPanel } from '../../../components/UI/Common/Tabs/Tabs';
import { Text } from '../../../components/UI/Typography';
import { useClauseLibrary } from '../../../hooks/useClauseLibrary';
import ClauseLibraryTable from '../../../components/ClauseLibrary/Table';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Add } from '@mui/icons-material';
import { MeliorTranslate } from '../../../components/MeliorTranslate';
import { MeliorTable } from '../../../components/MeliorTable';
import { useState } from 'react';
import { PAGINATION_LIMIT } from '../../../constants';

const ClauseLibrary = () => {
    const { t } = useTranslation();
    const [page, setPage] = useState(1);

    const params = {
        page: page,
        size: PAGINATION_LIMIT,
    };

    const { isLoading, error, totalPages, clauseLibrary, refetch } = useClauseLibrary(params);

    const showLoading = isLoading;

    return (
        <Container>
            <Grid container justifyContent="flex-end" sx={{ marginBottom: 2 }}>
                <Button variant="contained" disabled={true} startIcon={<Add />}>
                    <Text uppercase customletterSpacing={0.05}>
                        <MeliorTranslate valueKey="Add Clause" />
                    </Text>
                </Button>
            </Grid>
            <CardContainer>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Flex justifyBetween>
                        <Tabs value={0} style={{ overflow: 'visible' }}>
                            <Tab label={<MeliorTranslate valueKey="Clause Library" />} />
                        </Tabs>
                    </Flex>
                </Box>
                <TabPanel value={0} index={0}>
                    <MeliorTable
                        showLoading={showLoading}
                        error={error}
                        items={clauseLibrary}
                        page={page}
                        totalPages={totalPages}
                        setPage={setPage}
                        tableEl={
                            <ClauseLibraryTable clauseLibrary={clauseLibrary} refetch={refetch} />
                        }
                        loadingMessage="Loading clause library"
                        emptyMessage={t('No clause library found')}
                        errorMessage="An error has occurred"
                    />
                </TabPanel>
            </CardContainer>
        </Container>
    );
};

export default ClauseLibrary;
