import { IAuditLog } from '../@types/AuditLog';
import { getUserSessionData } from '../utils/userSession';
import apiClient from './apiClient';

export const getAuditLogsByUser = async (startDate?: string, endDate?: string) => {
    const user = getUserSessionData();
    const username = user?.email.split('@')[0];
    return apiClient
        .get<IAuditLog[]>(`/auditlogs/`, {
            params: {
                user: username,
                start_date: startDate?.length ? startDate : undefined,
                end_date: endDate?.length ? endDate : undefined,
            },
        })
        .then((res) => res.data);
};
