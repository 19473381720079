import Flex from 'styled-flex-component';
import Container from '../../../components/UI/Common/Container';
import CardContainer from '../../../components/UI/Common/Card/Card';
import { Badge, Box, Tab, Tabs } from '@mui/material';
import { MeliorTranslate } from '../../../components/MeliorTranslate';
import { TabPanel } from '../../../components/UI/Common/Tabs/Tabs';
import { useTranslation } from 'react-i18next';
import { MeliorTable } from '../../../components/MeliorTable';
import AuditLogsTable from '../../../components/AuditLogs/Table';
import { useEffect, useState } from 'react';
import { DateRange, DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { useAuditLogs } from '../../../hooks/useAuditLogs';
import dayjs, { Dayjs } from 'dayjs';

const AuditLogsPage = () => {
    const { t } = useTranslation();
    const [dateRange, setDateRange] = useState<DateRange<Dayjs>>();

    const { isLoading, error, auditLogs, refetch } = useAuditLogs(
        'auditLogs',
        dateRange?.length ? dayjs(dateRange[0]).format('YYYY-MM-DD') : '',
        dateRange?.length ? dayjs(dateRange[1]).format('YYYY-MM-DD') : ''
    );

    const showLoading = isLoading;

    useEffect(() => {
        if (dateRange?.length === 2) {
            refetch();
        }
    }, [dateRange]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Flex justifyBetween>
                <span />
                <DateRangePicker
                    sx={{
                        '& .MuiInputBase-root': {
                            fontSize: '14px',
                            height: '37.125px',
                        },
                        '& .MuiFormLabel-root': {
                            fontSize: '14px',
                            top: '-5px',
                        },
                    }}
                    value={dateRange}
                    onChange={(e) => setDateRange(e as DateRange<Dayjs>)}
                    localeText={{ start: 'From', end: 'To' }}
                />
            </Flex>
            <Container topOuterSpacing={1.3}>
                <CardContainer>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Flex justifyBetween>
                            <Tabs value={0} style={{ overflow: 'visible' }}>
                                <Tab
                                    label={
                                        <Flex alignCenter>
                                            <span>
                                                <MeliorTranslate valueKey="Audit Logs" />
                                            </span>
                                            {!showLoading && (
                                                <Container leftOuterSpacing={1}>
                                                    <Badge
                                                        badgeContent={auditLogs.length}
                                                        color="primary"
                                                    />
                                                </Container>
                                            )}
                                        </Flex>
                                    }
                                />
                            </Tabs>
                        </Flex>
                    </Box>
                    <TabPanel value={0} index={0}>
                        <MeliorTable
                            showLoading={showLoading}
                            error={error}
                            items={auditLogs}
                            tableEl={<AuditLogsTable auditLogs={auditLogs} />}
                            loadingMessage="Loading audit logs"
                            emptyMessage={t('No audit logs found')}
                            errorMessage="An error has occurred"
                        />
                    </TabPanel>
                </CardContainer>
            </Container>
        </LocalizationProvider>
    );
};

export default AuditLogsPage;
