import React from 'react';
import Loading from '../UI/Common/Loading';
import { useTranslation } from 'react-i18next';
import ErrorPlaceholder from '../UI/Common/ErrorPlaceholder';
import Empty from '../UI/Common/Empty';
import { Pagination } from '@mui/material';
import Container from '../UI/Common/Container';
import Stack from '@mui/material/Stack';

interface Props {
    showLoading: boolean;
    error: unknown;
    items: any[];
    loadingMessage: string;
    errorMessage: string;
    tableEl: React.ReactNode;
    emptyMessage: React.ReactNode | string;
    page?: number;
    totalPages?: number;
    setPage?: (page: number) => void;
}

export const MeliorTable = ({
    showLoading,
    error,
    items,
    loadingMessage,
    errorMessage,
    tableEl,
    emptyMessage,
    page,
    totalPages,
    setPage,
}: Props) => {
    const { t } = useTranslation();
    return (
        <>
            {showLoading && <Loading message={t(loadingMessage)} />}
            {!showLoading && error && <ErrorPlaceholder message={t(errorMessage)} />}
            {!showLoading && !error && !Boolean(items.length) && <Empty message={emptyMessage} />}
            {!showLoading && !error && Boolean(items.length) && (
                <>
                    {(totalPages as number) > 1 && setPage && (
                        <Container topOuterSpacing={1} rightOuterSpacing={1} leftOuterSpacing={1}>
                            <Stack alignItems="end">
                                <Pagination
                                    onChange={(e, page) => setPage(page)}
                                    page={page}
                                    count={totalPages}
                                />
                            </Stack>
                        </Container>
                    )}
                    {tableEl}
                    {(totalPages as number) > 1 && setPage && (
                        <Container outerSpacing={1}>
                            <Stack alignItems="end">
                                <Pagination
                                    onChange={(e, page) => setPage(page)}
                                    page={page}
                                    count={totalPages}
                                />
                            </Stack>
                        </Container>
                    )}
                </>
            )}
        </>
    );
};
