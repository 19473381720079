import { NotificationsOutlined } from '@mui/icons-material';
import { IconButton, Chip } from '@mui/material';
import NavbarStyled from './Navbar.styled';
import Flex from 'styled-flex-component';
import { H6 } from '../Typography';
import { Theme } from '../../../theme';
import ProfileAvatar from './Avatar/Avatar';
import Container from '../Common/Container';
import CopyAccessTokenButton from '../CopyAccessTokenButton/CopyAccessTokenButton';
import { useLocation } from 'react-router-dom';
import getSubscriptionStatus from '../../../utils/getSubscriptionStatus';
import { SubscriptionStatus } from '../../../enums/SubscriptionStatus';
import LanguageMenu from './LanguageMenu';
import { MeliorTranslate } from '../../MeliorTranslate';

interface NavbarProps {
    isSideBarMaximized: boolean;
}

const Navbar = ({ isSideBarMaximized }: NavbarProps) => {
    const location = useLocation();

    const subscriptionStatus = getSubscriptionStatus();

    function renderPageTitle() {
        if (location.pathname.includes('advanced-search'))
            return (
                <H6>
                    <MeliorTranslate valueKey="Advanced Search" />
                </H6>
            );
        if (location.pathname.includes('documents'))
            return (
                <H6>
                    <MeliorTranslate valueKey="Documents" />
                </H6>
            );
        if (location.pathname.includes('saved-queries'))
            return (
                <H6>
                    <MeliorTranslate valueKey="Saved Queries" />
                </H6>
            );
        if (location.pathname.includes('clause-library'))
            return (
                <H6>
                    <MeliorTranslate valueKey="Clause Library" />
                </H6>
            );
        if (location.pathname.includes('comparison'))
            return (
                <H6>
                    <MeliorTranslate valueKey="Comparisons" />
                </H6>
            );
        if (location.pathname.includes('workflows'))
            return (
                <H6>
                    <MeliorTranslate valueKey="Workflows" />
                </H6>
            );
        if (location.pathname.includes('insights-template'))
            return (
                <H6>
                    <MeliorTranslate valueKey="Insight Templates" />
                </H6>
            );
        if (location.pathname.includes('audit-logs'))
            return (
                <H6>
                    <MeliorTranslate valueKey="Audit Logs" />
                </H6>
            );
        return <H6>&nbsp;</H6>;
    }

    const getSubscriptionColor = () => {
        switch (subscriptionStatus) {
            case SubscriptionStatus.TRIAL:
                return 'warning';
            case SubscriptionStatus.PAYMENT_OVERDUE:
                return 'error';
            default:
                return 'success';
        }
    };

    return (
        <NavbarStyled isSideBarMaximized={isSideBarMaximized}>
            <Container leftInnerSpacing={1.5} rightInnerSpacing={1.5}>
                <Flex justifyBetween alignCenter>
                    {renderPageTitle()}

                    <Flex justifyEvenly alignCenter>
                        <Container leftOuterSpacing={0.5}>
                            <Flex justifyEvenly alignCenter>
                                <IconButton>
                                    <NotificationsOutlined
                                        sx={{ fontSize: 20, color: Theme.primaryDark }}
                                    />
                                </IconButton>
                                <CopyAccessTokenButton />
                                <LanguageMenu />
                            </Flex>
                        </Container>
                        <Container leftInnerSpacing={0.5}>
                            <Chip
                                label={subscriptionStatus}
                                color={getSubscriptionColor()}
                                variant="outlined"
                                style={{ width: '150px' }}
                            />
                        </Container>
                        <Container leftOuterSpacing={0.875}>
                            <ProfileAvatar />
                        </Container>
                    </Flex>
                </Flex>
            </Container>
        </NavbarStyled>
    );
};

export default Navbar;
