import CardContainer from '../../../components/UI/Common/Card/Card';
import Container from '../../../components/UI/Common/Container';
import DocumentsTable from '../../../components/Documents/Tables/Documents';
import { TabPanel } from '../../../components/UI/Common/Tabs/Tabs';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Badge, Box, Tab, Tabs } from '@mui/material';
import Flex from 'styled-flex-component';
import { useDocuments } from '../../../hooks/useDocuments';
import BoxUploadDocumentButton from '../../../components/UI/BoxUploadDocumentButton';
import { useLazyEffect } from '../../../hooks/useLazyEffect';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import DeleteDocumentDialog from '../../../components/Documents/Tables/Documents/DeleteDocumentDialog';
import { ClauseData, EntityType, IDocument } from '../../../@types/Document';
import AddTagModal from '../../../components/Documents/AddTagModal';
import { getTags } from '../../../api/tags';
import { ITagItem } from '../../../@types/Tag';
import { tagsToArray } from '../../../utils/transformTags';
import { MeliorTranslate } from '../../../components/MeliorTranslate';
import { MeliorTable } from '../../../components/MeliorTable';
import MultipleDocActions from '../../../components/UI/MultipleDocActions';
import DocumentFilters from '../../../components/UI/DocumentFilters';
import { StyledDocumentFiltersContainer } from '../../../components/UI/DocumentFilters/DocumentFiltersContainer.styled';

const DocumentsPage = () => {
    const [selectedType, setSelectedType] = useState<string>('All');
    const [selectedDocuments, setSelectedDocuments] = useState<IDocument[]>([]);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    const [displayedDocuments, setDisplayedDocuments] = useState<IDocument[]>([]);
    const [isAddTagOpen, setIsAddTagOpen] = useState(false);
    const [taggingMode, setTaggingMode] = useState<string>('');

    const [page, setPage] = useState(1);
    const [rowsPerPage] = useState(10);
    const [documentTags, setDocumentTags] = useState<object>({});
    const [taggingDocIds, setTaggingDocIds] = useState<string[]>([]);

    const [allTags, setAllTags] = useState<Array<ITagItem>>([]);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);

    const { t } = useTranslation();

    const params = {
        page: page,
        size: rowsPerPage,
        sort_by: '_upload_timestamp',
        sort_order: -1,
        name_filter: searchText,
        type_filter: selectedType === 'All' ? '' : selectedType,
        tags_filter: selectedTags,
    };

    const handleUploadSuccess = (res) => {
        toast.success('Successfully uploaded document');
        const newDocs: IDocument[] = [];
        res.entries.forEach((doc) => {
            newDocs.push({
                id: doc.sha1,
                name: doc.name,
                status: doc.status,
                type: doc.type,
                clauses: {} as ClauseData,
                entities: {} as EntityType,
                box_details: doc.box_details,
                storage_details: doc.storage_details,
                tags: {},
            });
        });
        setDisplayedDocuments(newDocs.concat(displayedDocuments));
    };

    const handleUploadError = (error) => {
        if (error.message) {
            toast.error(error.message);
            return;
        }
        toast.error(error.response.data.message);
    };

    const { isLoading, error, documents, totalPages, refetch } = useDocuments(params, 'documents');

    useEffect(() => {
        getAllTags();
    }, []);

    useEffect(() => {
        setDisplayedDocuments(documents);
    }, [documents]);
    const removeDocuments = (docs: IDocument[]) => {
        setDisplayedDocuments(
            displayedDocuments.filter(
                (document) => !docs.map((doc) => doc.id).includes(document.id)
            )
        );
        setSelectedDocuments([]);
    };

    const showLoading = isLoading;

    const setNewlySelectedDocuments = (docs) => {
        if (!docs) return;

        setSelectedDocuments(docs);
        setTaggingDocIds(docs.map((doc) => doc.id));
        setDocTags(docs);
    };

    const setDocTags = (docs) => {
        let tagObject = {};
        docs.forEach((doc) => {
            tagObject = { ...tagObject, ...doc.tags };
        });
        setDocumentTags(tagObject);
    };

    useLazyEffect(() => {
        refetch();
    }, [selectedType, searchText, selectedTags]);

    const getAllTags = () => {
        getTags().then((res) => {
            setAllTags(tagsToArray(res.tags));
        });
    };

    const updateTable = (tags: ITagItem[], action) => {
        const docs = displayedDocuments;
        docs.forEach((doc) => {
            if (taggingDocIds.includes(doc.id)) {
                tags.forEach((tag) => {
                    if (action == 'add') doc.tags[tag.name] = tag.color;
                    else if (action == 'delete') delete doc.tags[tag.name];
                });
            }
        });
        setDisplayedDocuments(docs);
        setDocTags(docs.filter((doc) => taggingDocIds.includes(doc.id)));
    };

    const onDocumentReady = (doc: IDocument) => {
        const docIndex = displayedDocuments.findIndex((document) => document.id == doc.id);
        const updatedDocs = displayedDocuments;
        updatedDocs[docIndex] = doc;
        setDisplayedDocuments(updatedDocs);
    };

    return (
        <>
            <Flex alignStart justifyBetween>
                <StyledDocumentFiltersContainer>
                    <DocumentFilters
                        allTags={allTags}
                        searchText={searchText}
                        selectedType={selectedType}
                        selectedTags={selectedTags}
                        setSearchText={setSearchText}
                        setSelectedTags={setSelectedTags}
                        setSelectedType={setSelectedType}
                    />
                </StyledDocumentFiltersContainer>
                <BoxUploadDocumentButton
                    onUploadSuccess={handleUploadSuccess}
                    onUploadError={handleUploadError}
                />
            </Flex>
            <Container topOuterSpacing={1.3}>
                <CardContainer>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Flex justifyBetween>
                            <Tabs value={0} style={{ overflow: 'visible' }}>
                                <Tab
                                    label={
                                        <Flex alignCenter>
                                            <span>
                                                <MeliorTranslate valueKey="Document" />
                                            </span>
                                            {!showLoading && (
                                                <Container leftOuterSpacing={1}>
                                                    <Badge
                                                        badgeContent={displayedDocuments.length}
                                                        color="primary"
                                                    />
                                                </Container>
                                            )}
                                        </Flex>
                                    }
                                />
                            </Tabs>
                            {!isEmpty(selectedDocuments) && !showLoading && (
                                <MultipleDocActions
                                    setIsAddTagOpen={() => setIsAddTagOpen(true)}
                                    setTaggingMode={setTaggingMode}
                                    documentTags={documentTags}
                                    setShowDeleteDialog={setShowDeleteDialog}
                                    selectedDocuments={selectedDocuments}
                                />
                            )}
                        </Flex>
                    </Box>
                    <AddTagModal
                        isOpen={isAddTagOpen}
                        setIsOpen={() => setIsAddTagOpen(!isAddTagOpen)}
                        onSaveTagsSuccess={(tags, action) => {
                            setIsAddTagOpen(false);
                            getAllTags();
                            updateTable(tags, action);
                        }}
                        documentIds={taggingDocIds}
                        documentTags={documentTags}
                        mode={taggingMode}
                    />
                    <DeleteDocumentDialog
                        open={showDeleteDialog}
                        documents={selectedDocuments}
                        onClose={() => {
                            setShowDeleteDialog(false);
                        }}
                        onDeleteSuccess={() => {
                            setShowDeleteDialog(false);
                            removeDocuments(selectedDocuments);
                        }}
                    />
                    <TabPanel value={0} index={0}>
                        <MeliorTable
                            showLoading={showLoading}
                            error={error}
                            items={displayedDocuments}
                            page={page}
                            setPage={setPage}
                            totalPages={totalPages}
                            tableEl={
                                <DocumentsTable
                                    documents={displayedDocuments}
                                    refetch={refetch}
                                    removeDocuments={removeDocuments}
                                    setSelectedDocuments={(docs) => {
                                        setNewlySelectedDocuments(docs);
                                    }}
                                    selectedDocuments={selectedDocuments}
                                    setIsAddTagOpen={setIsAddTagOpen}
                                    setTaggingMode={setTaggingMode}
                                    setDocumentTags={setDocumentTags}
                                    setTaggingDocIds={setTaggingDocIds}
                                    setSelectedTags={setSelectedTags}
                                    selectedTags={selectedTags}
                                    setSelectedType={setSelectedType}
                                    onDocumentReady={(document) => onDocumentReady(document)}
                                />
                            }
                            loadingMessage="Loading documents"
                            emptyMessage={t('No documents found')}
                            errorMessage="An error has occurred"
                        />
                    </TabPanel>
                </CardContainer>
            </Container>
        </>
    );
};

export default DocumentsPage;
