import Container from '../../../components/UI/Common/Container';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Text } from '../../../components/UI/Typography';
import { Button, Grid } from '@mui/material';
import { Theme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import SearchBox from '../../../components/UI/Navbar/SearchBox';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { isEmpty } from 'lodash';
import Flex from 'styled-flex-component';
import { Add } from '@mui/icons-material';
import { useDocumentTypes } from '../../../hooks/useDocumentTypes';
import { deleteTemplate, getTemplates } from '../../../api/template.api';
import { useClauseLibrary } from '../../../hooks/useClauseLibrary';
import { IClause } from '../../../@types/SavedQuery';
import { toast } from 'react-toastify';
import DeleteModal from '../../../components/UI/Common/Modal/DeleteModal';
import { ITemplate } from '../../../@types/Template';
import CreateListModal from '../../../components/Templates/CreateListModal';
import Loading from '../../../components/UI/Common/Loading';
import { MeliorTranslate } from '../../../components/MeliorTranslate';
import EmptyTemplates from '../../../components/InsightsTemplate/EmptyTemplates';
import TemplateList from '../../../components/InsightsTemplate/TemplateList';
import TemplateView from '../../../components/InsightsTemplate/TemplateView';
import InsightsTemplateProvider from './context/insightsTemplateContext';

const InsightsTemplate = () => {
    const { t } = useTranslation();
    const { isFetching, clauseLibrary } = useClauseLibrary();
    const { docTypes } = useDocumentTypes();

    const [templates, setTemplates] = useState<ITemplate[]>([]);
    const [initialTemplates, setInitialTemplates] = useState<any[]>([]);
    const [hoveredTemplate, setHoveredTemplate] = useState<number>(); //template index
    const [selectedTemplate, setSelectedTemplate] = useState<number>(-1); //template index
    const [isLoadingTypes, setIsLoadingTypes] = useState(true);
    const [allClauses, setAllClauses] = useState<string[]>([]);
    const [hiddenClauses, setHiddenClauses] = useState<string[]>([]);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [mode, setMode] = useState<string>('new');

    useEffect(() => {
        getTemplates().then((res) => {
            setIsLoadingTypes(false);
            const display = res.reverse(); //res is reversed to display latest on top
            setTemplates(display);
            setInitialTemplates(display);
            if (res.length) setSelectedTemplate(0);
        });
    }, [docTypes]);

    useEffect(() => {
        if (clauseLibrary) setAllClauses(clauseLibrary.map((item: IClause) => item.name));
    }, [isFetching]);

    useEffect(() => {
        if (selectedTemplate > -1 && templates.length) {
            const visible: string[] = templates[selectedTemplate].visible;
            setHiddenClauses(allClauses.filter((clause) => !visible.includes(clause)));
        }
    }, [allClauses, selectedTemplate, templates.length]);

    const doDelete = async () => {
        const currentTemplateName = templates[selectedTemplate].template_name;
        await deleteTemplate(currentTemplateName).then((res) => {
            if (res) {
                const updatedTemplates = templates.filter(
                    (template) => template.template_name != currentTemplateName
                );
                setTemplates(updatedTemplates);
                setInitialTemplates(updatedTemplates.concat([]));
                if (updatedTemplates.length) setSelectedTemplate(0);
                setIsDeleting(false);
                toast.success('Template deleted successfully.');
            }
        });
    };

    const doSearch = (searchKey) => {
        const filteredTemplates = searchKey.length
            ? initialTemplates.filter(
                  (template) =>
                      template.template_name.toLowerCase().indexOf(searchKey.toLowerCase()) > -1
              )
            : initialTemplates;
        setIsSearching(searchKey.length > 0);
        setTemplates(filteredTemplates);
        setSelectedTemplate(filteredTemplates.length ? 0 : -1);
        if (!filteredTemplates.length) {
            setHiddenClauses([]);
        }
    };

    const onSaveTemplateSuccess = (template: ITemplate) => {
        setIsOpen(false);
        if (mode == 'edit') {
            templates[selectedTemplate] = template;
            return;
        }
        setTemplates([template].concat(templates));
        setInitialTemplates([template].concat(templates));
    };

    return (
        <InsightsTemplateProvider
            templates={templates}
            initialTemplates={initialTemplates}
            hoveredTemplate={hoveredTemplate as number}
            selectedTemplate={selectedTemplate}
            isLoadingTypes={isLoadingTypes}
            allClauses={allClauses}
            hiddenClauses={hiddenClauses}
            isDeleting={isDeleting}
            isSearching={isSearching}
            isOpen={isOpen}
            mode={mode}
        >
            <Container>
                <Grid container justifyContent="space-between" sx={{ marginBottom: 2 }}>
                    <SearchBox
                        placeholder={t('Search insight lists')}
                        onChange={(searchKey) => doSearch(searchKey)}
                    />
                    <Button variant="contained" startIcon={<Add />}>
                        <Text
                            uppercase
                            color={Theme.whiteColor}
                            customletterSpacing={0.05}
                            onClick={() => {
                                setMode('new');
                                setIsOpen(true);
                            }}
                        >
                            <MeliorTranslate valueKey="Create List" />
                        </Text>
                    </Button>
                </Grid>
                {isEmpty(templates) && !isSearching ? (
                    <div
                        style={{
                            height: '80vh',
                            borderRadius: '14px',
                            background: 'white',
                            padding: 30,
                            paddingLeft: 20,
                        }}
                    >
                        <Flex alignCenter>
                            <Typography marginRight={2.5}>
                                <MeliorTranslate valueKey="Your Insight Lists" />
                            </Typography>
                        </Flex>
                        {isLoadingTypes ? (
                            <Loading message="Loading templates" />
                        ) : (
                            <EmptyTemplates setMode={setMode} setIsOpen={setIsOpen} />
                        )}
                    </div>
                ) : (
                    <PanelGroup autoSaveId="templateList" direction="horizontal">
                        <Panel defaultSize={20} minSize={20} order={1}>
                            <TemplateList
                                setSelectedTemplate={setSelectedTemplate}
                                setHoveredTemplate={setHoveredTemplate}
                            />
                        </Panel>
                        <PanelResizeHandle style={{ width: '5px' }} />
                        <Panel order={2}>
                            <TemplateView
                                setMode={setMode}
                                setIsOpen={setIsOpen}
                                setIsDeleting={setIsDeleting}
                            />
                        </Panel>
                    </PanelGroup>
                )}
                <DeleteModal
                    open={isDeleting}
                    itemToDelete={'this insight template'}
                    onCancel={() => setIsDeleting(false)}
                    onConfirm={() => doDelete()}
                />
                <CreateListModal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    clauses={clauseLibrary?.map((item: IClause) => item.name)}
                    onSaveTemplateSuccess={(template) => onSaveTemplateSuccess(template)}
                    maxStep={4}
                    mode={mode}
                    template={templates[selectedTemplate]}
                />
            </Container>
        </InsightsTemplateProvider>
    );
};

export default InsightsTemplate;
