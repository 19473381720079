import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { Text } from '../../UI/Typography';
import Container from '../../../components/UI/Common/Container';
import CreateComparisonModal from '../../Comparisons/Tables/CreateComparisonModal';
import Flex from 'styled-flex-component';
import { createComparisonTask } from '../../../api/comparison.api';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Close } from '@mui/icons-material';
import {
    ComparisonDetailContext,
    ComparisonDetailContextType,
} from '../../../pages/Client/ComparisonDetail/context/comparisonDetailContext';

interface IComparisonParamsProps {
    refetch: () => void;
}

export default function ComparisonParams({ refetch }: IComparisonParamsProps) {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const { queries, selectedQuery, comparisonClauses, comparisonDefault, comparisonDetails } =
        useContext(ComparisonDetailContext) as ComparisonDetailContextType;

    async function onCreateQuery(task) {
        await createComparisonTask(task).then((res) => {
            if (res) {
                toast.success(`Successfully updated comparison task`);
                refetch();
            }
        });
    }

    return (
        <>
            <Box sx={{ marginBottom: '20px', display: 'flex' }}>
                {/* Comparison Clauses */}
                {comparisonClauses && (
                    <Box sx={{ width: '50%', display: 'flex' }}>
                        <Box
                            sx={{
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center',
                                marginRight: '15px',
                            }}
                        >
                            <Text>Your Clauses</Text>
                        </Box>
                        <Box
                            sx={{
                                width: '75%',
                            }}
                        >
                            <CreateComparisonModal
                                isOpen={isEditing}
                                setIsOpen={setIsEditing}
                                onSave={(task) => {
                                    onCreateQuery(task);
                                }}
                                comparisonDefault={comparisonDefault}
                            />
                            <Box
                                sx={{
                                    border: '1px solid lightgray',
                                    borderRadius: '5px',
                                }}
                            >
                                <Box
                                    sx={{
                                        paddingX: (theme) => theme.spacing(0.5),
                                        paddingY: (theme) => theme.spacing(1),
                                        display: 'flex',
                                        gap: (theme) => theme.spacing(1),
                                        flexWrap: 'wrap',
                                        overflowY: 'auto',
                                        overflowX: 'hidden',
                                        height: '70px',
                                    }}
                                >
                                    {comparisonClauses.map((clause, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                borderRadius: '10px',
                                                height: '20px',
                                                background: 'rgba(16, 185, 129, 0.3)',
                                                paddingRight: '10px',
                                                paddingLeft: '10px',
                                                marginLeft: '5px',
                                                fontSize: '14px',
                                                alignItems: 'center',
                                                display: 'flex',
                                            }}
                                        >
                                            <p style={{ marginRight: '5px' }}>{clause}</p>
                                            <Close
                                                fontSize="small"
                                                onClick={() => setIsEditing(true)}
                                                sx={{ color: 'rgba(13, 24, 50, 0.54)' }}
                                            />
                                        </div>
                                    ))}
                                </Box>
                                <Flex justifyBetween alignCenter>
                                    <Box
                                        sx={{
                                            background: 'lightgray',
                                            borderRadius: '10px',
                                            width: '20px',
                                            height: '20px',
                                            marginLeft: '10px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Text customFontSize={0.8}>{comparisonClauses.length}</Text>
                                    </Box>
                                    <Button
                                        onClick={() => {
                                            setIsEditing(true);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                </Flex>
                            </Box>
                        </Box>
                    </Box>
                )}
                {/* Saved Query */}
                {comparisonDetails?.metadata?.saved_query && (
                    <Box sx={{ width: '50%', marginLeft: '20px', display: 'flex' }}>
                        <Box
                            sx={{
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center',
                                marginRight: '15px',
                            }}
                        >
                            <Text>Your Saved Query</Text>
                        </Box>
                        <Container width={'65%'} leftOuterSpacing={0.5} rightOuterSpacing={1}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                size="small"
                                options={queries}
                                sx={{ width: '100%' }}
                                value={selectedQuery}
                                renderInput={(params) => (
                                    <TextField
                                        {...(params as any)}
                                        onClick={() => {
                                            setIsEditing(true);
                                        }}
                                    />
                                )}
                            />
                        </Container>
                    </Box>
                )}
            </Box>
        </>
    );
}
