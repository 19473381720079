import { Route, Routes } from 'react-router-dom';
import ClientPage from './Client';
import LoginPage from './Login';
import InitializePage from './Initialize';
import Protected from '../guards/Protected';
import Anonymous from '../guards/Anonymous';
import VersionPage from './Version';

const PageRoot = () => {
    return (
        <Routes>
            <Route element={<Protected />}>
                <Route path="/client/*" element={<ClientPage />} />
                <Route path="/initialize" element={<InitializePage />} />
                <Route path="/version" element={<VersionPage />} />
            </Route>
            <Route element={<Anonymous />}>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/" element={<LoginPage />} />
            </Route>
        </Routes>
    );
};

export default PageRoot;
