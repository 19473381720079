import { Circle } from '@mui/icons-material';
import { Button, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import useAnnouncementBannerStyles from './useAnnouncementBannerStyles';
import { isEmpty, truncate } from 'lodash';
import AnnouncementBannerModal from './AnnouncementBannerModal';
import useDismissedAnnouncements from './useDismissedAnnouncments';
import { IAnnouncement } from '../../@types/Announcement';
import { getUserSessionData } from '../../utils/userSession';
import { useAnnouncementBanner } from '../../hooks/useAnnouncementBanner';

interface Props {
    styles?: React.CSSProperties;
}

export default function AnnouncementBanner({ ...carouselProps }: Props) {
    const user = getUserSessionData();
    const [showMore, setShowMore] = useState<IAnnouncement>();
    const [currentAnnouncementIndex, setCurrentAnnouncementIndex] = useState<number | undefined>(0);
    const theme = useAnnouncementBannerStyles();
    const messageLengthLimit = 200;
    const { isDismissed, dismissAnnouncement } = useDismissedAnnouncements();

    const { isLoading, announcementBanner } = useAnnouncementBanner(user);

    if (isLoading) return null;

    if (!announcementBanner || isEmpty(announcementBanner)) return null;

    const displayedAnnouncements = announcementBanner.filter(
        (item) => !isDismissed(item._id as string)
    );

    if (isEmpty(displayedAnnouncements)) return null;

    return (
        <>
            <Carousel
                onChange={(index) => setCurrentAnnouncementIndex(index)}
                index={currentAnnouncementIndex}
                animation="slide"
                autoPlay={false}
                swipe={true}
                IndicatorIcon={<Circle style={{ fontSize: '8px', margin: '-3em .5em 0 .5em' }} />}
                navButtonsAlwaysInvisible={true}
                navButtonsWrapperProps={{
                    style: {
                        margin: '0 0 0 1em',
                        height: '100%',
                    },
                }}
                {...carouselProps}
            >
                {displayedAnnouncements.map((item, index) => (
                    <Paper key={item._id} style={{ ...theme[item.type as string], height: 'auto' }}>
                        <Typography variant="h6">{item.title}</Typography>

                        <Typography variant="body2">
                            {truncate(item.message, {
                                length: messageLengthLimit,
                                separator: ' ',
                            })}
                        </Typography>

                        <Button
                            variant="text"
                            style={{
                                textAlign: 'right',
                                float: 'right',
                                fontStyle: 'italic',
                            }}
                            onClick={() => {
                                dismissAnnouncement(item._id as string);
                                setCurrentAnnouncementIndex(index + 1);
                            }}
                        >
                            Dismiss
                        </Button>
                        {item.message.length > messageLengthLimit && (
                            <Button
                                variant="text"
                                style={{
                                    textAlign: 'right',
                                    float: 'right',
                                    fontStyle: 'italic',
                                }}
                                onClick={() => setShowMore(item)}
                            >
                                Show more
                            </Button>
                        )}
                    </Paper>
                ))}
            </Carousel>
            <AnnouncementBannerModal
                open={!isEmpty(showMore)}
                onClose={() => setShowMore(undefined)}
                title={showMore?.title}
                message={showMore?.message}
                type={showMore?.type}
            />
        </>
    );
}
