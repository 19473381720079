import { Button, Typography } from '@mui/material';
import { MeliorTranslate } from '../../MeliorTranslate';
import { Add } from '@mui/icons-material';
import { Text } from '../../UI/Typography';
import { Theme } from '../../../theme';
import Flex from 'styled-flex-component';

interface IEmptyTemplatesProps {
    setMode: (mode: string) => void;
    setIsOpen: (val: boolean) => void;
}

export default function EmptyTemplates({ setMode, setIsOpen }: IEmptyTemplatesProps) {
    return (
        <Flex justifyCenter alignCenter style={{ height: '60vh' }}>
            <Flex column>
                <Typography fontSize={14}>
                    <MeliorTranslate valueKey="You have no Insight Template yet." />
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginTop: 10 }}
                    startIcon={<Add />}
                    onClick={() => {
                        setMode('new');
                        setIsOpen(true);
                    }}
                >
                    <Text uppercase color={Theme.whiteColor} customletterSpacing={0.05}>
                        <MeliorTranslate valueKey="Create your first one" />
                    </Text>
                </Button>
            </Flex>
        </Flex>
    );
}
