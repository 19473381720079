import adminAPIClient from '../adminapi/apiClient';

export function GetOrg() {
    return adminAPIClient.get('tenants/me').then((res) => res.data);
}

export function GetOrgMetadata() {
    return GetOrg().then((org) => {
        const metadata = org?.metadata;

        if (typeof metadata === 'string') {
            return JSON.parse(metadata);
        }

        return metadata;
    });
}
