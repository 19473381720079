import { Box, Typography } from '@mui/material';
import { MeliorTranslate } from '../../MeliorTranslate';
import { Carousel } from 'react-responsive-carousel';
import { useEffect } from 'react';
import { cards } from '../../../constants/testimonies';

export default function TestimonialCards() {
    useEffect(() => {
        if (document.querySelector('#launcher-frame')) {
            document.querySelector<HTMLElement>('#launcher-frame')!.style.display = 'none';
        }
    }, []);

    return (
        <div
            style={{
                width: '50%',
                padding: '30px',
                backgroundColor: '#0D1832',
                borderRadius: '30px',
                minHeight: '100%',
                paddingTop: '50px',
            }}
        >
            <Typography align="left" color="white" fontSize="36px" fontWeight="600" gutterBottom>
                <MeliorTranslate valueKey="Melior AI makes navigating stacks of legal contracts and other common business documents a breeze!" />
            </Typography>
            <Box
                sx={{
                    bgcolor: '#2C3449',
                    mt: 8,
                    borderRadius: 5,
                }}
            >
                <Carousel autoPlay={true} infiniteLoop={true} showStatus={false}>
                    {cards.map((card, i) => (
                        <Box key={i} sx={{ marginBottom: '50px', padding: '20px' }}>
                            <Typography
                                variant="body1"
                                color="#BDC0C7"
                                fontWeight="600"
                                fontSize="18px"
                                paragraph
                            >
                                {`"${card.testimony}"`}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                }}
                            >
                                <img
                                    style={{
                                        borderRadius: '50%',
                                        height: '40px',
                                        width: '40px',
                                        marginRight: '20px',
                                    }}
                                    alt="Testimony Customer Image"
                                    src={card.customerInfo.image}
                                />
                                <span>
                                    <Typography color="white" fontSize="18px">
                                        {card.customerInfo.name}
                                    </Typography>
                                    <Typography fontSize="16px" color="#5C6273">
                                        {card.customerInfo.position}, {card.customerInfo.company}
                                    </Typography>
                                </span>
                            </Box>
                        </Box>
                    ))}
                </Carousel>
            </Box>
        </div>
    );
}
