import {
    Autocomplete,
    Box,
    Button,
    FormLabel,
    InputAdornment,
    Modal,
    TextField,
} from '@mui/material';
import Container from '../../../UI/Common/Container';
import { H5, Text } from '../../../UI/Typography';
import Flex from 'styled-flex-component';
import {
    AddCircleOutline,
    Close,
    CloseOutlined,
    RemoveCircleOutline,
    SearchOutlined,
} from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSavedQueries } from '../../../../hooks/useSavedQueries';
import { StyledSearchTextField } from '../../../AdvancedSearch/SearchSectionInput/StyledTextField';
import { useClauses } from '../../../../hooks/useClauses';
import { deleteComparisonTask } from '../../../../api/comparison.api';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MeliorTranslate } from '../../../MeliorTranslate';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    p: 4,
    borderRadius: '10px',
    height: 400,
};

interface ISaveComparisonModalProps {
    isOpen: boolean;
    setIsOpen: (val: boolean) => void;
    onSave: (task: URLSearchParams) => void;
    comparisonDefault: any;
}

export default function CreateComparisonModal({
    isOpen,
    setIsOpen,
    onSave,
    comparisonDefault,
}: ISaveComparisonModalProps) {
    const { t } = useTranslation();
    const [comparisonName, setComparisonName] = useState<string>('');
    const [comparisonDescription, setComparisonDescription] = useState<string>('');
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [step, setStep] = useState<number>(1);

    const [dirtyComparisonName, setDirtyComparisonName] = useState<boolean>(false);
    const [dirtyComparisonDesc, setDirtyComparisonDesc] = useState<boolean>(false);

    const [page] = useState(0);
    const [rowsPerPage] = useState(50);
    const [queries, setQueries] = useState([]);
    const [selectedQuery, setSelectedQuery] = useState('');

    const { clauses } = useClauses();
    const [selectedClauses, setSelectedClauses] = useState<string[]>([]);
    const [clauseList, setClauseList] = useState<string[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const navigate = useNavigate();

    const params = {
        page: page + 1,
        size: rowsPerPage,
    };

    const { isLoading, savedQueries } = useSavedQueries(params);

    useEffect(() => {
        const names = savedQueries.map((query) => query.query_name);
        setQueries(names);
        setSelectedQuery(names[0]);
    }, [isLoading]);

    async function createQuery() {
        setIsSaving(true);

        try {
            if (comparisonDefault) {
                const deleteResponse = await deleteComparisonTask(comparisonDefault.id);
                if (!deleteResponse) {
                    return;
                }
            }

            const params = new URLSearchParams();
            params.append('name', comparisonName);
            params.append('description', comparisonDescription);
            params.append('saved_query', selectedQuery);
            selectedClauses.forEach((clause) => {
                params.append('clauses', clause);
            });
            await onSave(params);
            setIsSaving(false);
            setIsOpen(false);

            if (comparisonDefault) {
                navigate(`/client/comparisons`);
            }
        } catch (e) {
            setIsSaving(false);
            toast.error('Something went wrong.');
        }
    }

    const resetForm = () => {
        setComparisonName('');
        setComparisonDescription('');
        setSelectedQuery(queries[0]);
        setSelectedClauses([]);
        setDirtyComparisonName(false);
        setDirtyComparisonDesc(false);
    };

    const cancelCreate = () => {
        setIsOpen(false);
        setStep(1);
        if (!comparisonDefault) {
            resetForm();
        }
    };

    const selectFilter = (filter: string) => {
        if (selectedClauses.find((option) => option === filter)) {
            const temp = [...selectedClauses];
            temp.splice(
                selectedClauses.findIndex((option) => option === filter),
                1
            );
            setSelectedClauses(temp);
            return;
        }

        setSelectedClauses([...selectedClauses, filter]);
    };

    const isFilterAdded = (clause: string): boolean => {
        return Boolean(selectedClauses.find((option) => option === clause));
    };

    const filterClauses = (filterText: string) => {
        if (!filterText) {
            setClauseList(clauses);
            return;
        }

        const tempList: string[] = [];
        clauses.map((clause) => {
            if (clause.toLowerCase().includes(filterText.toLowerCase())) {
                tempList.push(clause);
            }

            return clause;
        });

        setClauseList(tempList);
    };

    useEffect(() => {
        if (clauses && clauses.length) {
            setClauseList(clauses);
        }
    }, [clauses]);

    useEffect(() => {
        if (comparisonDefault) {
            setSelectedClauses(comparisonDefault.clauses);
            setComparisonName(comparisonDefault.name);
            setComparisonDescription(comparisonDefault.description);
            setSelectedQuery(comparisonDefault.saved_query);
        }
    }, [comparisonDefault]);

    return (
        <Modal
            open={isOpen}
            aria-labelledby="save-query-modal-title"
            aria-describedby="save-query-modal-description"
        >
            <Box sx={style}>
                <>
                    <Flex justifyBetween alignTop>
                        <Container>
                            <Container bottomOuterSpacing={0.75}>
                                {step == 1 && (
                                    <H5>
                                        <MeliorTranslate valueKey="Create Comparison" />
                                    </H5>
                                )}
                                {step == 2 && (
                                    <H5>
                                        <MeliorTranslate valueKey="Clause List" />
                                    </H5>
                                )}
                                {step == 3 && (
                                    <H5>
                                        <MeliorTranslate valueKey="Comparison: Review Details" />
                                    </H5>
                                )}
                            </Container>
                        </Container>
                        <Container
                            onClick={() => {
                                cancelCreate();
                            }}
                        >
                            <CloseOutlined
                                sx={{ color: 'rgba(0, 0, 0, 0.54)', cursor: 'pointer' }}
                            />
                        </Container>
                    </Flex>
                    <Container topOuterSpacing={1}>
                        {step != 2 && (
                            <Container>
                                <Flex>
                                    <TextField
                                        required
                                        autoComplete="off"
                                        fullWidth
                                        id="comparison-name"
                                        label={t('Name')}
                                        placeholder={t('Comparison name')}
                                        onChange={(e) => {
                                            setComparisonName(e.target.value);
                                            setDirtyComparisonName(true);
                                        }}
                                        value={comparisonName}
                                        error={comparisonName === '' && dirtyComparisonName}
                                        helperText={
                                            comparisonName === '' && dirtyComparisonName
                                                ? t('This field is required')
                                                : ' '
                                        }
                                        style={{ width: '50%', marginRight: '10px' }}
                                    />
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={queries}
                                        sx={{ width: '50%' }}
                                        value={selectedQuery}
                                        onChange={(event, newValue) => {
                                            setSelectedQuery(newValue ?? '');
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...(params as any)}
                                                label={t('Your saved queries')}
                                            />
                                        )}
                                    />
                                </Flex>
                                <Flex>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        multiline
                                        fullWidth
                                        label={t('Description')}
                                        autoComplete="off"
                                        placeholder={t('Add description')}
                                        onChange={(e) => {
                                            setComparisonDescription(e.target.value);
                                            setDirtyComparisonDesc(true);
                                        }}
                                        value={comparisonDescription}
                                        rows={6}
                                        error={comparisonDescription === '' && dirtyComparisonDesc}
                                        helperText={
                                            comparisonDescription === '' && dirtyComparisonDesc
                                                ? t('This field is required')
                                                : ' '
                                        }
                                        style={{ width: '50%', marginRight: '10px' }}
                                    />
                                    {step == 3 && (
                                        <Box sx={{ width: '50%' }}>
                                            <FormLabel
                                                sx={{
                                                    marginLeft: '0.71em',
                                                    marginTop: '-0.71em',
                                                    paddingLeft: '0.44em',
                                                    paddingRight: '0.44em',
                                                    zIndex: 2,
                                                    backgroundColor: (theme) =>
                                                        theme.palette.background.default,
                                                    position: 'absolute',
                                                    fontSize: '0.75em',
                                                    width: 'auto',
                                                }}
                                            >
                                                <MeliorTranslate valueKey="Clauses" />
                                            </FormLabel>
                                            <Box
                                                sx={{
                                                    position: 'relative',
                                                    borderRadius: (theme) =>
                                                        theme.shape.borderRadius + 'px',
                                                    fontSize: '0.875rem',
                                                    border: '1px solid lightgray',
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    alignContent: 'flex-start',
                                                    height: '170px',
                                                    overflowY: 'auto',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        padding: (theme) => theme.spacing(1),
                                                        display: 'flex',
                                                        gap: (theme) => theme.spacing(1),
                                                        flexWrap: 'wrap',
                                                        overflow: 'auto',
                                                    }}
                                                >
                                                    {selectedClauses.map((clause, index) => (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                borderRadius: '10px',
                                                                height: '26px',
                                                                background:
                                                                    'rgba(16, 185, 129, 0.3)',
                                                                paddingRight: '10px',
                                                                paddingLeft: '10px',
                                                                marginLeft: '5px',
                                                                fontSize: '16px',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                display: 'flex',
                                                                marginTop: '10px',
                                                            }}
                                                        >
                                                            <p style={{ marginRight: '5px' }}>
                                                                {clause}
                                                            </p>
                                                            <Close
                                                                fontSize="small"
                                                                onClick={() => selectFilter(clause)}
                                                            />
                                                        </div>
                                                    ))}
                                                </Box>
                                                <fieldset
                                                    aria-hidden={'true'}
                                                    style={{
                                                        textAlign: 'left',
                                                        position: 'absolute',
                                                        bottom: 0,
                                                        right: 0,
                                                        top: '-5px',
                                                        left: 0,
                                                        margin: 0,
                                                        padding: '0 8px',
                                                        pointerEvents: 'none',
                                                        borderRadius: 'inherit',
                                                        borderStyle: 'solid',
                                                        borderWidth: '1px',
                                                        overflow: 'hidden',
                                                        minWidth: '0%',
                                                        borderColor: 'rgba(255, 255, 255, 0.23)',
                                                    }}
                                                >
                                                    <legend
                                                        style={{
                                                            float: 'unset',
                                                            overflow: 'hidden',
                                                            display: 'block',
                                                            width: 'auto',
                                                            padding: 0,
                                                            height: '11px',
                                                            fontSize: '0.75em',
                                                            visibility: 'hidden',
                                                            maxWidth: '50%',
                                                            // '-webkit-transition': 'max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms',
                                                            transition:
                                                                'max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms',
                                                            whiteSpace: 'nowrap',
                                                        }}
                                                    >
                                                        <span>
                                                            <MeliorTranslate valueKey="Clauses" />
                                                        </span>
                                                    </legend>
                                                </fieldset>
                                            </Box>
                                        </Box>
                                    )}
                                </Flex>
                            </Container>
                        )}
                        {step == 2 && (
                            <Container>
                                <Container height="273px" overflow="scroll">
                                    <Container bottomOuterSpacing={1.5}>
                                        <StyledSearchTextField
                                            autoFocus
                                            autoComplete="off"
                                            fullWidth
                                            placeholder={t('Search for filters')}
                                            size="small"
                                            value={searchText}
                                            sx={{ background: 'rgba(0, 0, 0, 0.06)' }}
                                            onChange={(e) => {
                                                setSearchText(e.target.value);
                                                filterClauses(e.target.value);
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchOutlined />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {searchText && (
                                                            <Container
                                                                topOuterSpacing={0.4}
                                                                cursor="pointer"
                                                                onClick={() => {
                                                                    setSearchText('');
                                                                    filterClauses('');
                                                                }}
                                                            >
                                                                <Close fontSize="small" />
                                                            </Container>
                                                        )}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Container>
                                    {clauseList &&
                                        Boolean(clauseList.length) &&
                                        clauseList.map((clause, index) => (
                                            <Container
                                                key={index}
                                                borderTop="1px solid rgba(13, 24, 50, 0.12)"
                                                borderLeft="1px solid rgba(13, 24, 50, 0.12)"
                                                borderRight="1px solid rgba(13, 24, 50, 0.12)"
                                                borderBottom={
                                                    clauseList.length - 1 === index
                                                        ? '1px solid rgba(13, 24, 50, 0.12)'
                                                        : 'none'
                                                }
                                                leftInnerSpacing={1}
                                                rightInnerSpacing={1.375}
                                                topInnerSpacing={1.25}
                                                bottomInnerSpacing={1.25}
                                                cursor="pointer"
                                                onClick={() => selectFilter(clause)}
                                                backgroundColor={
                                                    isFilterAdded(clause)
                                                        ? 'rgba(16, 185, 129, 0.3)'
                                                        : ''
                                                }
                                            >
                                                <Flex alignCenter justifyBetween>
                                                    <Text
                                                        color="rgba(13, 24, 50, 0.87)"
                                                        customFontSize={1}
                                                    >
                                                        {clause}
                                                    </Text>
                                                    {isFilterAdded(clause) && (
                                                        <RemoveCircleOutline
                                                            sx={{ color: 'rgba(13, 24, 50, 0.54)' }}
                                                        />
                                                    )}
                                                    {!isFilterAdded(clause) && (
                                                        <AddCircleOutline
                                                            sx={{ color: 'rgba(13, 24, 50, 0.54)' }}
                                                        />
                                                    )}
                                                </Flex>
                                            </Container>
                                        ))}
                                </Container>
                            </Container>
                        )}
                    </Container>
                    <Container topInnerSpacing={1}>
                        <Flex justifyEnd>
                            {/* Cancel */}
                            {step == 1 && (
                                <Container>
                                    <Button
                                        onClick={() => {
                                            cancelCreate();
                                        }}
                                        disabled={isSaving}
                                    >
                                        <Text
                                            customFontFamily="Poppins"
                                            customFontWeight={500}
                                            uppercase
                                        >
                                            <MeliorTranslate valueKey="Cancel" />
                                        </Text>
                                    </Button>
                                </Container>
                            )}
                            {/* Back */}
                            {step != 1 && (
                                <Container>
                                    <Button onClick={() => setStep(step - 1)} disabled={isSaving}>
                                        <Text
                                            customFontFamily="Poppins"
                                            customFontWeight={500}
                                            uppercase
                                        >
                                            <MeliorTranslate valueKey="Back" />
                                        </Text>
                                    </Button>
                                </Container>
                            )}
                            {/* Next */}
                            {step != 3 && (
                                <Container leftOuterSpacing={0.5}>
                                    <Button
                                        onClick={() => setStep(step + 1)}
                                        disabled={isSaving}
                                        variant="contained"
                                    >
                                        <Text
                                            customFontFamily="Poppins"
                                            customFontWeight={500}
                                            uppercase
                                        >
                                            <MeliorTranslate valueKey="Next" />
                                        </Text>
                                    </Button>
                                </Container>
                            )}
                            {/* Create */}
                            {step == 3 && (
                                <Container leftOuterSpacing={0.5}>
                                    <Button
                                        variant="contained"
                                        onClick={createQuery}
                                        disabled={
                                            isSaving ||
                                            !comparisonName ||
                                            !comparisonDescription ||
                                            !selectedClauses.length
                                        }
                                    >
                                        <Text
                                            customFontFamily="Poppins"
                                            customFontWeight={500}
                                            uppercase
                                        >
                                            {comparisonDefault ? (
                                                <MeliorTranslate valueKey="Update" />
                                            ) : (
                                                <MeliorTranslate valueKey="Create" />
                                            )}
                                        </Text>
                                    </Button>
                                </Container>
                            )}
                        </Flex>
                    </Container>
                </>
            </Box>
        </Modal>
    );
}
