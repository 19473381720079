import { Button, IconButton } from '@mui/material';
import { IDocument } from '../../../@types/Document';
import { Edit } from '@mui/icons-material';
import DocumentTags from '../DocumentTags';
import Flex from 'styled-flex-component';
import { Text } from '../Typography';
import { MeliorTranslate } from '../../MeliorTranslate';
import { useState } from 'react';
import { DocumentStatus } from '../../../enums/DocumentStatus';

interface IDocumentTableItemTagsProps {
    document: IDocument;
    docTags: string[];
    selectedTags: string[];
    addTag: () => void;
    setSelectedTags: (tags: string[]) => void;
}

const DocumentTableItemTags = ({
    document,
    docTags,
    selectedTags,
    addTag,
    setSelectedTags,
}: IDocumentTableItemTagsProps) => {
    const [hoveredDoc, setHoveredDoc] = useState<string>('');
    return (
        <span
            style={{
                height: '38px',
            }}
        >
            {docTags.length ? (
                <div
                    style={{
                        minWidth: '210px',
                        height: '58px',
                    }}
                    onMouseEnter={() => setHoveredDoc(document.id)}
                    onMouseLeave={() => setHoveredDoc('')}
                >
                    {Boolean(hoveredDoc == document.id) && (
                        <IconButton
                            style={{
                                height: '24px',
                                width: '24px',
                                marginRight: '5px',
                                cursor: 'pointer',
                            }}
                            onClick={() => addTag()}
                        >
                            <Edit fontSize="small" color="primary" />
                        </IconButton>
                    )}
                    <DocumentTags
                        documentData={document}
                        selectedTags={docTags}
                        onClickTag={(tag) => {
                            if (!selectedTags.includes(tag))
                                setSelectedTags(selectedTags.concat([tag]));
                        }}
                        onClickMore={() => addTag()}
                    />
                </div>
            ) : (
                <Flex alignCenter justifyBetween>
                    <Button
                        variant="outlined"
                        disabled={document.status != DocumentStatus.READY}
                        onClick={() => addTag()}
                    >
                        <Text>
                            <MeliorTranslate valueKey="Add Tags" />
                        </Text>
                    </Button>
                </Flex>
            )}
        </span>
    );
};

export default DocumentTableItemTags;
