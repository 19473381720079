export const headerLinks = [
    {
        title: 'Solutions',
        target: 'https://www.melior.ai/#solutionsSection',
    },
    {
        title: 'Product',
        target: 'https://www.melior.ai/#productsSection',
    },
    {
        title: 'Price Plans',
        target: 'https://www.melior.ai/#pricingSection',
    },
];
