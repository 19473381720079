import './App.css';
import Main from './components/Main';
import Pages from './pages';
import { BrowserRouter } from 'react-router-dom';
import { SupportedScreenWrapper } from './components/UI/ScreenSize';
import NoticeUnsupportedScreen from './components/UI/Notice/UnsupportedScreen';
import { FronteggProvider } from '@frontegg/react';
import { frontEggConfig } from './constants/frontEggConfig';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ThemeWrapper from './components/ThemeWrapper/ThemeWrapper';
import './i18n';

const queryClient = new QueryClient();

function App() {
    return (
        <ThemeWrapper>
            <NoticeUnsupportedScreen message="Hey the app is not available on screen size lower than 737px in width"></NoticeUnsupportedScreen>
            <SupportedScreenWrapper>
                <QueryClientProvider client={queryClient}>
                    <Main>
                        <BrowserRouter>
                            <FronteggProvider
                                contextOptions={frontEggConfig}
                                authOptions={{
                                    keepSessionAlive: true,
                                    hostedLoginOptions: {
                                        loadUserOnFirstLoad: true,
                                    },
                                }}
                                hostedLoginBox={true}
                            >
                                <Pages />
                                <ToastContainer />
                            </FronteggProvider>
                        </BrowserRouter>
                    </Main>
                </QueryClientProvider>
            </SupportedScreenWrapper>
        </ThemeWrapper>
    );
}

export default App;
