import { createContext } from 'react';
import { IComparisonDetail, IComparisonResult } from '../../../../@types/Comparison';

export type ComparisonDetailContextType = {
    comparisonDetails: IComparisonDetail;
    results: IComparisonResult;
    comparisonClauses: Array<string>;
    queries: [];
    page: number;
    rowsPerPage: number;
    selectedQuery: string;
    isLoadingComparison: boolean;
    comparisonDefault: object;
};

export const ComparisonDetailContext = createContext<ComparisonDetailContextType | null>(null);

interface ComparisonDetailProviderProps {
    children: React.ReactNode;
    comparisonDetails: IComparisonDetail;
    results: IComparisonResult;
    comparisonClauses: Array<string>;
    queries: [];
    page: number;
    rowsPerPage: number;
    selectedQuery: string;
    isLoadingComparison: boolean;
    comparisonDefault: object;
}

const ComparisonDetailProvider = ({
    children,
    comparisonDetails,
    results,
    comparisonClauses,
    queries,
    page,
    rowsPerPage,
    selectedQuery,
    isLoadingComparison,
    comparisonDefault,
}: ComparisonDetailProviderProps) => {
    return (
        <ComparisonDetailContext.Provider
            value={{
                comparisonDetails,
                results,
                comparisonClauses,
                queries,
                page,
                rowsPerPage,
                selectedQuery,
                isLoadingComparison,
                comparisonDefault,
            }}
        >
            {children}
        </ComparisonDetailContext.Provider>
    );
};

export default ComparisonDetailProvider;
