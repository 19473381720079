import { getUserSessionData } from './userSession';

/**
 *
 * @returns boolean
 * This function checks in user's metadata whethere user is allowed to upload/delete a button or not.
 */
export function canChangeADocument() {
    const user = getUserSessionData();
    if (!user) return false;

    const metadata = JSON.parse(user.metadata);
    if (metadata.preventDocumentChanges === 'true') {
        return false;
    }
    return true;
}
