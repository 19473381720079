import customerImgGreg from '../images/Greg.png';
import customerImgMatt from '../images/Matt.png';
import customerImgKeren from '../images/Keren.png';
import customerImgLuis from '../images/Luis.png';
import customerImgAlex from '../images/Alex.png';
import customerImgAbi from '../images/Abi.png';

export const cards = [
    {
        testimony: `This powerful tool has transformed our workflow, 
    boosting efficiency and providing invaluable insights.
    The platform's ability to effortlessly extract and organize information 
    from various documents has saved us a tremendous amount of time and effort. 
    Its accuracy and advanced natural language processing 
    algorithms enable us to uncover hidden patterns and make informed decisions.`,
        customerInfo: {
            image: customerImgMatt,
            name: 'Matthew Binns',
            position: 'Managing Director',
            company: 'Utilipay',
        },
    },
    {
        testimony: `Melior has completely transformed the way I analyze documents. 
    It's easy to use and has saved me so much time. 
    I highly recommend it!`,
        customerInfo: {
            image: customerImgGreg,
            name: 'Greg Morris',
            position: 'CEO',
            company: 'Sebata Holdings',
        },
    },
    {
        testimony: `Melior has totally upended my approach to document analysis. 
    It's intuitive, it's user-friendly, 
    and it has clawed back many hours of my time. 
    In my view, you'd be hard-pressed to find something better. 
    Do yourself a favor and give it a shot!`,
        customerInfo: {
            image: customerImgKeren,
            name: 'Keren Cohen',
            position: 'COO',
            company: 'Midos Commercial Properties, Inc.',
        },
    },
    {
        testimony: `Love it!`,
        customerInfo: {
            image: customerImgAlex,
            name: 'Alex Bouaziz',
            position: 'CEO & Co-Founder',
            company: 'Deel',
        },
    },
    {
        testimony: `Finally, an easy to use tool that reliably helps me find key data in all sorts of legal documents.`,
        customerInfo: {
            image: customerImgLuis,
            name: 'Luis Barreto',
            position: 'Head of Strategic Projects',
            company: 'Andbank Group',
        },
    },
    {
        testimony: `Incredible`,
        customerInfo: {
            image: customerImgAbi,
            name: 'Abi Mohamed',
            position: 'Tech Director',
            company: 'CGVentures',
        },
    },
    // Add more cards as needed
];
