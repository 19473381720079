import CardContainer from '../../../components/UI/Common/Card/Card';
import Container from '../../../components/UI/Common/Container';
import ComparisonsTable from '../../../components/Comparisons/Tables';
import { TabPanel } from '../../../components/UI/Common/Tabs/Tabs';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Badge, Box, Button, Tab, Tabs } from '@mui/material';
import Flex from 'styled-flex-component';
import { useLazyEffect } from '../../../hooks/useLazyEffect';
import { useComparisons } from '../../../hooks/useComparisons';
import CreateComparisonModal from '../../../components/Comparisons/Tables/CreateComparisonModal';
import { Text } from '../../../components/UI/Typography';
import { Add } from '@mui/icons-material';
import { createComparisonTask } from '../../../api/comparison.api';
import { useTranslation } from 'react-i18next';
import { MeliorTranslate } from '../../../components/MeliorTranslate';
import { MeliorTable } from '../../../components/MeliorTable';
import { PAGINATION_LIMIT } from '../../../constants';

const ComparisonPage = () => {
    const { t } = useTranslation();
    const [selectedType] = useState<string>('All');
    const [searchText] = useState<string>('');
    const [isCreateComparisonModalOpen, setIsCreateComparisonModalOpen] = useState<boolean>(false);
    const [page, setPage] = useState(1);

    const params = {
        page: page,
        size: PAGINATION_LIMIT,
    };

    async function onCreateQuery(task) {
        await createComparisonTask(task).then((res) => {
            if (res) {
                toast.success(`Successfully created comparison task`);
                refetch();
            }
        });
    }

    const { isLoading, error, comparisons, totalPages, refetch } = useComparisons(params);

    const showLoading = isLoading;

    useLazyEffect(() => {
        refetch();
    }, [selectedType, searchText]);

    return (
        <>
            <Flex alignCenter justifyEnd>
                <Button
                    variant="contained"
                    onClick={() => setIsCreateComparisonModalOpen(true)}
                    startIcon={<Add />}
                >
                    <Text uppercase customletterSpacing={0.05}>
                        <MeliorTranslate valueKey="Create Comparison" />
                    </Text>
                </Button>

                <CreateComparisonModal
                    isOpen={isCreateComparisonModalOpen}
                    setIsOpen={setIsCreateComparisonModalOpen}
                    onSave={(task) => {
                        onCreateQuery(task);
                    }}
                    comparisonDefault={null}
                />
            </Flex>
            <Container topOuterSpacing={1.3}>
                <CardContainer>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Flex justifyBetween>
                            <Tabs value={0} style={{ overflow: 'visible' }}>
                                <Tab
                                    label={
                                        <Flex alignCenter>
                                            <span>
                                                <MeliorTranslate valueKey="Comparisons" />
                                            </span>
                                            {!showLoading && (
                                                <Container leftOuterSpacing={1}>
                                                    <Badge
                                                        badgeContent={comparisons?.length}
                                                        color="primary"
                                                    />
                                                </Container>
                                            )}
                                        </Flex>
                                    }
                                />
                            </Tabs>
                        </Flex>
                    </Box>
                    <TabPanel value={0} index={0}>
                        <MeliorTable
                            showLoading={showLoading}
                            error={error}
                            items={comparisons}
                            page={page}
                            totalPages={totalPages}
                            setPage={setPage}
                            tableEl={
                                <ComparisonsTable comparisons={comparisons} refetch={refetch} />
                            }
                            loadingMessage="Loading comparisons"
                            emptyMessage={t('No comparisons found')}
                            errorMessage="An error has occurred"
                        />
                    </TabPanel>
                </CardContainer>
            </Container>
        </>
    );
};

export default ComparisonPage;
