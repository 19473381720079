import { useQuery } from '@tanstack/react-query';
import { fetchClauseLibrary } from '../api/clauseLibrary.api';

export function useClauseLibrary(params?) {
    const { data, ...rest } = useQuery({
        queryFn: () => fetchClauseLibrary(params).then((res) => res),
        queryKey: ['clauses', params],
        refetchOnWindowFocus: false,
    });

    return {
        clauseLibrary: data?.items,
        totalPages: data?.pages,
        ...rest,
    };
}
