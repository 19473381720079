import { useQuery } from '@tanstack/react-query';
import { IAuditLog } from '../@types/AuditLog';
import { getAuditLogsByUser } from '../api/auditLogs';

export function useAuditLogs(queryKey, startDate?: string, endDate?: string) {
    const { data, ...rest } = useQuery({
        queryFn: () => getAuditLogsByUser(startDate, endDate),
        queryKey: [queryKey],
        refetchOnWindowFocus: false,
        refetchOnMount: true,
    });

    return {
        auditLogs: data as IAuditLog[],
        ...rest,
    };
}
