import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { useLoginWithRedirect, useAuth } from '@frontegg/react';
import { SvgMeliorLogo3 } from '../../components/UI/icons/SvgMeliorLogo';
import { saveUserSessionData } from '../../utils/userSession';
import { User } from '@frontegg/redux-store/auth/interfaces';
import { useEffect, useState } from 'react';
import { Link } from '@mui/material';
import { isEmpty } from 'lodash';
import { Theme } from '../../theme';
import { Widget } from '@typeform/embed-react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { MeliorTranslate } from '../../components/MeliorTranslate';
import TestimonialCards from '../../components/Login/TestimonialCards';
import { headerLinks } from '../../constants/loginHeaderLinks';
import getStorageUserId from '../../utils/getStorageUserId';

const LoginPage = () => {
    const { user } = useAuth();
    const [displayForm, toggleDisplayForm] = useState<boolean>(false);
    const loginWithRedirect = useLoginWithRedirect();
    const storageUserId = getStorageUserId(user?.metadata);

    const handleIsAuthenticated = () => {
        saveUserSessionData(user as User);

        if (isEmpty(storageUserId)) {
            window.location.href = `/initialize`;
        } else {
            const redirectTo = localStorage.getItem('redirectTo') ?? '';
            const next = !isEmpty(redirectTo) ? redirectTo : `/client/documents`;

            window.location.href = next;
        }
    };

    useEffect(() => {
        if (document.querySelector('#launcher-frame')) {
            document.querySelector<HTMLElement>('#launcher-frame')!.style.display = 'none';
        }

        if (user?.accessToken) handleIsAuthenticated();
    }, [user, loginWithRedirect, displayForm]);

    function login() {
        loginWithRedirect();
    }

    return (
        <div style={{ height: '100vh' }}>
            <AppBar
                style={{
                    backgroundColor: Theme.whiteColor,
                }}
                position="relative"
            >
                <Toolbar>
                    <SvgMeliorLogo3 />
                    {headerLinks.map((link, i) => (
                        <Link
                            href={link.target}
                            marginLeft="30px"
                            fontWeight="700"
                            color="text.secondary"
                            underline="none"
                            sx={{ pl: 2 }}
                            key={i}
                            noWrap
                        >
                            {link.title}
                        </Link>
                    ))}
                </Toolbar>
            </AppBar>
            <div
                style={{
                    padding: '30px',
                    minHeight: 'calc(100vh - 125px)',
                    display: 'flex',
                }}
            >
                <TestimonialCards />
                <Box
                    sx={{
                        width: '50%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: 'fit-content',
                        alignContent: 'center',
                    }}
                >
                    {displayForm && (
                        <span
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                height: '70vh',
                                padding: '10px',
                            }}
                        >
                            <IconButton
                                aria-label="back"
                                color="primary"
                                onClick={() => {
                                    toggleDisplayForm(false);
                                }}
                            >
                                <ArrowBackIosNewIcon />
                            </IconButton>
                            <Widget id="N9V4ZRey" style={{ height: '90%', width: '100%' }} />
                        </span>
                    )}
                    {!displayForm && (
                        <Stack
                            direction="row"
                            sx={{ mt: 35 }}
                            spacing={2}
                            justifyContent="center"
                            alignContent="center"
                        >
                            <Button
                                variant="outlined"
                                style={{ textTransform: 'none', width: '30%' }}
                                color="secondary"
                                onClick={() => {
                                    toggleDisplayForm(!displayForm);
                                }}
                                size="large"
                            >
                                <MeliorTranslate valueKey="Request an Invite" />
                            </Button>
                            <Button
                                variant="contained"
                                onClick={login}
                                style={{ textTransform: 'none', width: '30%' }}
                                size="large"
                            >
                                <MeliorTranslate valueKey="Login" />
                            </Button>
                        </Stack>
                    )}
                </Box>
            </div>
        </div>
    );
};

export default LoginPage;
