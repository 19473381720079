import { useEffect, useState } from 'react';
import { getComparison } from '../../../api/comparison.api';
import { useParams } from 'react-router-dom';
import { IComparisonDetail, IComparisonResult } from '../../../@types/Comparison';
import { Box } from '@mui/material';
import { useSavedQueries } from '../../../hooks/useSavedQueries';
import PageLoadingPlaceholder from '../../../components/PageLoadingPlaceholder/PageLoadingPlaceholder';
import ComparisonParams from '../../../components/ComparisonDetail/ComparisonParams';
import ComparisonDetailProvider from './context/comparisonDetailContext';
import ComparisonAccordion from '../../../components/ComparisonDetail/ComparisonAccordion';

const ComparisonDetailPage = () => {
    const [comparisonDetails, setComparisonDetails] = useState<IComparisonDetail>();
    const routeParams = useParams();
    const comparisonId = routeParams['id'];
    const [results, setResults] = useState<IComparisonResult>();
    const [comparisonClauses, setComparisonClauses] = useState<Array<string>>([]);
    const [queries, setQueries] = useState([]);
    const [page] = useState(0);
    const [rowsPerPage] = useState(50);
    const [selectedQuery, setSelectedQuery] = useState('');
    const [isLoadingComparison, setIsLoadingComparison] = useState(true);
    const [comparisonDefault, setComparisonDefault] = useState<object>();
    const { savedQueries, refetch } = useSavedQueries({
        page: page + 1,
        size: rowsPerPage,
    });

    useEffect(() => {
        getComparisonData();
    }, []);

    useEffect(() => {
        const names = savedQueries.map((query) => query.query_name);
        setQueries(names);
        setSelectedQuery(names[0]);
    }, []);

    const getComparisonData = () => {
        getComparison(comparisonId as string).then((res) => {
            setIsLoadingComparison(true);
            setComparisonDetails(res);
            setSelectedQuery(res.metadata.saved_query);
            const comparisonContainer: any = [];
            res.comparisons.forEach((comparison: any) => {
                const keys = Object.keys(comparison).sort();
                const keyClauses = keys.filter((key) => key != 'doc_id' && key != 'doc_name');

                if (!comparisonClauses.length) {
                    setComparisonClauses(keyClauses);
                    setComparisonDefault({
                        name: res.name,
                        description: res.description,
                        clauses: keyClauses,
                        saved_query: res.metadata.saved_query,
                        id: res.id,
                    });
                }

                let resultsContainer: any = [];
                keyClauses.forEach((clause) => {
                    if (comparison[clause] != null)
                        resultsContainer.push({
                            clause: clause,
                            diff: comparison[clause].diff,
                            entailment: comparison[clause].entailment,
                            paraphrasing: comparison[clause].paraphrasing,
                        });
                });
                comparisonContainer.unshift({
                    doc_id: comparison.doc_id,
                    doc_name: comparison.doc_name,
                    results: resultsContainer,
                });
                resultsContainer = [];
            });
            setResults(comparisonContainer);
            setIsLoadingComparison(false);
        });
    };

    if (isLoadingComparison) {
        return (
            <Box
                sx={{
                    width: '100%',
                    height: '85vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <PageLoadingPlaceholder />
            </Box>
        );
    }

    return (
        <ComparisonDetailProvider
            comparisonDetails={comparisonDetails as IComparisonDetail}
            results={results as IComparisonResult}
            comparisonClauses={comparisonClauses}
            queries={queries as []}
            page={page}
            rowsPerPage={rowsPerPage}
            selectedQuery={selectedQuery}
            isLoadingComparison={isLoadingComparison}
            comparisonDefault={comparisonDefault as object}
        >
            <ComparisonParams refetch={refetch} />
            <ComparisonAccordion comparisonId={comparisonId as string} />
        </ComparisonDetailProvider>
    );
};

export default ComparisonDetailPage;
