import { ITag } from '../@types/Tag';
import apiClient from './apiClient';

export const getTags = async () => {
    return apiClient
        .get(`/tags`)
        .then((res) => res.data)
        .catch((err) => Promise.reject({ ...err }));
};

export const addTags = async (tagList: ITag) => {
    return apiClient
        .put(`/tags`, tagList)
        .then((res) => res.data)
        .catch((err) => Promise.reject({ ...err }));
};

export const deleteTags = async (tagList: ITag) => {
    return apiClient
        .delete(`/tags`, { data: tagList })
        .then((res) => res.data)
        .catch((err) => Promise.reject({ ...err }));
};
