import { Navigate, Outlet } from 'react-router-dom';
import { getUserSessionData } from '../utils/userSession';
import { removeRedirectTo } from '../utils/userConfig';

export default function Anonymous() {
    const user = getUserSessionData();

    if (localStorage.getItem('redirectTo') == 'loggedOut') removeRedirectTo();

    return user?.accessToken ? (
        <Navigate to={localStorage.getItem('redirectTo') ?? '/client/documents'} replace />
    ) : (
        <Outlet />
    );
}
